/*
   * Extra utilities
   */
.flex-equal > * {
    -ms-flex: 1;
    flex: 1;
  }
  @media (min-width: 768px) {
    .flex-md-equal > * {
      -ms-flex: 1;
      flex: 1;
    }
}
.overflow-hidden { overflow: hidden; }
.plain-link{
  text-decoration:none; color:inherit !important;
}
.hide{
  display: none;
}