/*      light colors - used for select dropdown         */
/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  src: url("../fonts/nucleo-icons.eot");
  src: url("../fonts/nucleo-icons.eot") format("embedded-opentype"), url("../fonts/nucleo-icons.woff2") format("woff2"), url("../fonts/nucleo-icons.woff") format("woff"), url("../fonts/nucleo-icons.ttf") format("truetype"), url("../fonts/nucleo-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

/*------------------------
	base class definition
-------------------------*/
.nc-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'nucleo-icons';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*------------------------
  change icon size
-------------------------*/
.nc-icon.lg {
  font-size: 1.33333333em;
  vertical-align: -16%; }

.nc-icon.x2 {
  font-size: 2em; }

.nc-icon.x3 {
  font-size: 3em; }

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nc-icon.square,
.nc-icon.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee; }

.nc-icon.circle {
  border-radius: 50%; }

/*------------------------
  list icons
-------------------------*/
.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.nc-icon-ul > li {
  position: relative; }

.nc-icon-ul > li > .nc-icon {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center; }

.nc-icon-ul > li > .nc-icon.lg {
  top: 0;
  left: -1.35714286em; }

.nc-icon-ul > li > .nc-icon.circle,
.nc-icon-ul > li > .nc-icon.square {
  top: -0.19047619em;
  left: -1.9047619em; }

/*------------------------
  spinning icons
-------------------------*/
.nc-icon.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear; }

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*------------------------
  rotated/flipped icons
-------------------------*/
.nc-icon.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.nc-icon.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.nc-icon.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

.nc-icon.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.nc-icon.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1); }

/*------------------------
	font icons
-------------------------*/
.nc-air-baloon::before {
  content: "\ea01"; }

.nc-album-2::before {
  content: "\ea02"; }

.nc-alert-circle-i::before {
  content: "\ea04"; }

.nc-align-center::before {
  content: "\ea03"; }

.nc-align-left-2::before {
  content: "\ea05"; }

.nc-ambulance::before {
  content: "\ea06"; }

.nc-app::before {
  content: "\ea07"; }

.nc-atom::before {
  content: "\ea08"; }

.nc-badge::before {
  content: "\ea09"; }

.nc-bag-16::before {
  content: "\ea0a"; }

.nc-bank::before {
  content: "\ea0b"; }

.nc-basket::before {
  content: "\ea0c"; }

.nc-bell-55::before {
  content: "\ea0d"; }

.nc-bold::before {
  content: "\ea0e"; }

.nc-book-bookmark::before {
  content: "\ea0f"; }

.nc-bookmark-2::before {
  content: "\ea10"; }

.nc-box-2::before {
  content: "\ea11"; }

.nc-box::before {
  content: "\ea12"; }

.nc-briefcase-24::before {
  content: "\ea13"; }

.nc-bulb-63::before {
  content: "\ea14"; }

.nc-bullet-list-67::before {
  content: "\ea15"; }

.nc-bus-front-12::before {
  content: "\ea16"; }

.nc-button-pause::before {
  content: "\ea17"; }

.nc-button-play::before {
  content: "\ea18"; }

.nc-button-power::before {
  content: "\ea19"; }

.nc-calendar-60::before {
  content: "\ea1a"; }

.nc-camera-compact::before {
  content: "\ea1b"; }

.nc-caps-small::before {
  content: "\ea1c"; }

.nc-cart-simple::before {
  content: "\ea1d"; }

.nc-chart-bar-32::before {
  content: "\ea1e"; }

.nc-chart-pie-36::before {
  content: "\ea1f"; }

.nc-chat-33::before {
  content: "\ea20"; }

.nc-check-2::before {
  content: "\ea21"; }

.nc-circle-10::before {
  content: "\ea22"; }

.nc-cloud-download-93::before {
  content: "\ea23"; }

.nc-cloud-upload-94::before {
  content: "\ea24"; }

.nc-compass-05::before {
  content: "\ea25"; }

.nc-controller-modern::before {
  content: "\ea26"; }

.nc-credit-card::before {
  content: "\ea27"; }

.nc-delivery-fast::before {
  content: "\ea28"; }

.nc-diamond::before {
  content: "\ea29"; }

.nc-email-85::before {
  content: "\ea2a"; }

.nc-favourite-28::before {
  content: "\ea2b"; }

.nc-glasses-2::before {
  content: "\ea2c"; }

.nc-globe-2::before {
  content: "\ea2d"; }

.nc-globe::before {
  content: "\ea2e"; }

.nc-hat-3::before {
  content: "\ea2f"; }

.nc-headphones::before {
  content: "\ea30"; }

.nc-html5::before {
  content: "\ea31"; }

.nc-image::before {
  content: "\ea32"; }

.nc-istanbul::before {
  content: "\ea33"; }

.nc-key-25::before {
  content: "\ea34"; }

.nc-laptop::before {
  content: "\ea35"; }

.nc-layout-11::before {
  content: "\ea36"; }

.nc-lock-circle-open::before {
  content: "\ea37"; }

.nc-map-big::before {
  content: "\ea38"; }

.nc-minimal-down::before {
  content: "\ea39"; }

.nc-minimal-left::before {
  content: "\ea3a"; }

.nc-minimal-right::before {
  content: "\ea3b"; }

.nc-minimal-up::before {
  content: "\ea3c"; }

.nc-mobile::before {
  content: "\ea3d"; }

.nc-money-coins::before {
  content: "\ea3e"; }

.nc-note-03::before {
  content: "\ea3f"; }

.nc-palette::before {
  content: "\ea40"; }

.nc-paper::before {
  content: "\ea41"; }

.nc-pin-3::before {
  content: "\ea42"; }

.nc-planet::before {
  content: "\ea43"; }

.nc-refresh-69::before {
  content: "\ea44"; }

.nc-ruler-pencil::before {
  content: "\ea45"; }

.nc-satisfied::before {
  content: "\ea46"; }

.nc-scissors::before {
  content: "\ea47"; }

.nc-send::before {
  content: "\ea48"; }

.nc-settings-gear-65::before {
  content: "\ea49"; }

.nc-settings::before {
  content: "\ea4a"; }

.nc-share-66::before {
  content: "\ea4b"; }

.nc-shop::before {
  content: "\ea4c"; }

.nc-simple-add::before {
  content: "\ea4d"; }

.nc-simple-delete::before {
  content: "\ea4e"; }

.nc-simple-remove::before {
  content: "\ea4f"; }

.nc-single-02::before {
  content: "\ea50"; }

.nc-single-copy-04::before {
  content: "\ea51"; }

.nc-sound-wave::before {
  content: "\ea52"; }

.nc-spaceship::before {
  content: "\ea53"; }

.nc-sun-fog-29::before {
  content: "\ea54"; }

.nc-support-17::before {
  content: "\ea55"; }

.nc-tablet-2::before {
  content: "\ea56"; }

.nc-tag-content::before {
  content: "\ea57"; }

.nc-tap-01::before {
  content: "\ea58"; }

.nc-tie-bow::before {
  content: "\ea59"; }

.nc-tile-56::before {
  content: "\ea5a"; }

.nc-time-alarm::before {
  content: "\ea5b"; }

.nc-touch-id::before {
  content: "\ea5c"; }

.nc-trophy::before {
  content: "\ea5d"; }

.nc-tv-2::before {
  content: "\ea5e"; }

.nc-umbrella-13::before {
  content: "\ea5f"; }

.nc-user-run::before {
  content: "\ea60"; }

.nc-vector::before {
  content: "\ea61"; }

.nc-watch-time::before {
  content: "\ea62"; }

.nc-world-2::before {
  content: "\ea63"; }

.nc-zoom-split::before {
  content: "\ea64"; }

/* all icon font classes list here */
/*           Font Smoothing      */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, a, .td-name, td, button, input, select, textarea {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Montserrat", "Helvetica", Arial, sans-serif;
  font-weight: 300; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin: 30px 0 0; }

h1, .h1 {
  font-size: 3.6em; }

h2, .h2 {
  font-size: 2.8em; }

h3, .h3 {
  font-size: 1.825em;
  line-height: 1.4;
  margin: 20px 0 0px; }

h4, .h4 {
  font-size: 1.6em;
  line-height: 1.2em; }

h5, .h5 {
  font-size: 1.35em;
  line-height: 1.4em; }

h6, .h6 {
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em; }

p {
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: 5px; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  color: #9A9A9A;
  line-height: 1.5em; }

h1 small, h2 small, h3 small, h1 .small, h2 .small, h3 .small {
  font-size: 60%; }

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-family: "Montserrat", "Helvetica", Arial, sans-serif; }
  .title,
  .title a,
  .card-title,
  .card-title a,
  .info-title,
  .info-title a,
  .footer-brand,
  .footer-brand a,
  .footer-big h5,
  .footer-big h5 a,
  .footer-big h4,
  .footer-big h4 a,
  .media .media-heading,
  .media .media-heading a {
    color: #333333;
    text-decoration: none; }

.title-uppercase {
  text-transform: uppercase; }

.description {
  color: #9A9A9A; }

blockquote small {
  font-style: normal; }

.text-muted {
  color: #DDDDDD; }

.text-primary, .text-primary:hover {
  color: #51cbce !important; }

.text-info, .text-info:hover {
  color: #51bcda !important; }

.text-success, .text-success:hover {
  color: #6bd098 !important; }

.text-warning, .text-warning:hover {
  color: #fbc658 !important; }

.text-danger, .text-danger:hover {
  color: #f5593d !important; }

.glyphicon {
  line-height: 1; }

.heart {
  color: #EB5E28;
  animation: heathing 1s ease infinite; }

@keyframes heathing {
  0% {
    transform: scale(0.75); }
  20% {
    transform: scale(1); }
  40% {
    transform: scale(0.75); }
  60% {
    transform: scale(1); }
  80% {
    transform: scale(0.75); }
  100% {
    transform: scale(0.75); } }

.footer .credits,
.footer-nav {
  line-height: 85px; }

.footer .btn {
  margin-bottom: 0; }

.blockquote {
  border-left: 0 none;
  border-bottom: 1px solid #CCC5B9;
  border-top: 1px solid #CCC5B9;
  font-weight: 300;
  margin: 15px 0 10px;
  text-align: center; }

.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px; }

.title.text-center {
  margin-bottom: 50px; }

/*     General overwrite     */
body {
  color: #66615b;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Montserrat', "Helvetica", Arial, sans-serif; }

a {
  color: #51bcda; }
  a:hover, a:focus {
    color: #2ba9cd;
    text-decoration: none; }

hr {
  border-color: #F1EAE0; }

.icon {
  fill: #66615b; }

.fa-base {
  font-size: 1.25em !important; }

a:focus, a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0; }

.ui-slider-handle:focus,
.navbar-toggle {
  outline: 0 !important; }

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert,
.carousel-control.right,
.carousel-control.left {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.tagsinput .tag,
.tagsinput-remove-link,
.filter,
.btn-hover,
[data-toggle="collapse"] i,
.animation-transition-fast,
.dropdown-menu .dropdown-item {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear; }

.btn-morphing .fa,
.btn-morphing .circle,
.gsdk-collapse {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.fa {
  width: 18px;
  text-align: center; }

.margin-top {
  margin-top: 50px; }

.iframe-container iframe {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

/*       CT colors          */
.ct-blue {
  color: #51cbce; }

.ct-azure {
  color: #51bcda; }

.ct-green {
  color: #6bd098; }

.ct-orange {
  color: #fbc658; }

.ct-red {
  color: #f5593d; }

.pagination .page-item .page-link .fa {
  width: auto;
  font-weight: 600; }

.bg-primary {
  background-color: #6dd3d6 !important; }

.bg-info {
  background-color: #6ec7e0 !important; }

.bg-success {
  background-color: #86d9ab !important; }

.bg-warning {
  background-color: #fcd27b !important; }

.bg-danger {
  background-color: #f7765f !important; }

.wrapper {
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  left: 0;
  background-color: #FFFFFF;
  position: relative;
  z-index: 1; }

.heart {
  color: #EB5E28;
  animation: heathing 1s ease infinite; }

@keyframes heathing {
  0% {
    transform: scale(0.75); }
  20% {
    transform: scale(1); }
  40% {
    transform: scale(0.75); }
  60% {
    transform: scale(1); }
  80% {
    transform: scale(0.75); }
  100% {
    transform: scale(0.75); } }

.bd-docs .page-header {
  min-height: 50vh; }

.offline-doc .navbar.navbar-transparent {
  padding-top: 25px;
  border-bottom: none; }
  .offline-doc .navbar.navbar-transparent .navbar-minimize {
    display: none; }
  .offline-doc .navbar.navbar-transparent .navbar-brand,
  .offline-doc .navbar.navbar-transparent .collapse .navbar-nav .nav-link {
    color: #FFFFFF !important; }

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  z-index: 3; }
  .offline-doc .footer nav > ul a:not(.btn),
  .offline-doc .footer .copyright {
    color: #FFFFFF !important; }

.offline-doc .page-header .container {
  z-index: 3; }

.offline-doc .page-header:after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }

.section-buttons .btn,
.section-buttons .btn-morphing {
  margin-bottom: 10px; }

.btn.btn-just-icon .nc-icon {
  top: 0; }

.btn.dropdown-toggle:focus {
  box-shadow: none !important; }

.dropup .dropdown-menu .dropdown-item:last-child {
  border-bottom: none; }

.dropup .dropdown-menu::after {
  bottom: -11px !important; }

.dropup .dropdown-menu::before {
  bottom: -12px !important; }

.section .section-project .projects-3 {
  padding-bottom: 0; }

.bootstrap-select .dropdown-menu[x-placement="top-start"] .dropdown-menu.inner {
  margin-bottom: -50px; }

#modals .btn,
.btn[data-toggle="tooltip"],
.btn[data-toggle="popover"] {
  margin-bottom: 10px; }

#modals .btn {
  margin-right: 10px; }

.btn {
  box-sizing: border-box;
  border-width: 2px;
  font-size: 12px;
  font-weight: 600;
  padding: 0.5rem 18px;
  line-height: 1.75;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #66615B;
  border-color: #66615B;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear; }
  .btn.btn-border, .btn.btn-link {
    background-color: transparent; }
  .btn:hover, .btn:focus, .btn:active, .btn.active, .btn:active:focus, .btn:active:hover, .btn.active:focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled).active:focus,
  .show > .btn.dropdown-toggle {
    background-color: #403D39;
    color: #FFFFFF;
    border-color: #403D39;
    box-shadow: none; }
  .btn .caret {
    border-top-color: #FFFFFF; }
  .btn.disabled, .btn.disabled:hover, .btn.disabled:focus, .btn.disabled.focus, .btn.disabled:active, .btn.disabled.active, .btn:disabled, .btn:disabled:hover, .btn:disabled:focus, .btn:disabled.focus, .btn:disabled:active, .btn:disabled.active, .btn[disabled], .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled].focus, .btn[disabled]:active, .btn[disabled].active,
  fieldset[disabled] .btn,
  fieldset[disabled] .btn:hover,
  fieldset[disabled] .btn:focus,
  fieldset[disabled] .btn.focus,
  fieldset[disabled] .btn:active,
  fieldset[disabled] .btn.active {
    background-color: #403D39;
    border-color: #403D39; }
  .btn.btn-link {
    color: #66615B; }
    .btn.btn-link:hover, .btn.btn-link:focus, .btn.btn-link:active, .btn.btn-link.active, .btn.btn-link:focus:active,
    .open > .btn.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #403D39 !important; }
    .btn.btn-link .caret {
      border-top-color: #66615B; }
  .btn .caret {
    border-top-color: #FFFFFF; }
  .btn:hover, .btn:focus {
    outline: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn:active, .btn.active,
  .open > .btn.dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
  .btn[class*="btn-outline-"] {
    background-image: none;
    background-color: transparent; }
  .btn .nc-icon {
    position: relative;
    top: 2px; }

.btn-just-icon {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  padding: 8px; }
  .btn-just-icon.btn-sm {
    padding: 4px !important; }
  .btn-just-icon i {
    font-size: 16px;
    padding: 2px 0px; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -2px; }

.btn-primary {
  background-color: #51cbce;
  border-color: #51cbce;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle {
    background-color: #34b5b8;
    color: #FFFFFF;
    border-color: #34b5b8;
    box-shadow: none; }
  .btn-primary .caret {
    border-top-color: #FFFFFF; }
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary:disabled, .btn-primary:disabled:hover, .btn-primary:disabled:focus, .btn-primary:disabled.focus, .btn-primary:disabled:active, .btn-primary:disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background-color: #34b5b8;
    border-color: #34b5b8; }
  .btn-primary.btn-link {
    color: #51cbce; }
    .btn-primary.btn-link:hover, .btn-primary.btn-link:focus, .btn-primary.btn-link:active, .btn-primary.btn-link.active, .btn-primary.btn-link:focus:active,
    .open > .btn-primary.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #34b5b8 !important; }
    .btn-primary.btn-link .caret {
      border-top-color: #51cbce; }
  .btn-primary .caret {
    border-top-color: #FFFFFF; }

.btn-success {
  background-color: #6bd098;
  border-color: #6bd098;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success:active:focus, .btn-success:active:hover, .btn-success.active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle {
    background-color: #44c47d;
    color: #FFFFFF;
    border-color: #44c47d;
    box-shadow: none; }
  .btn-success .caret {
    border-top-color: #FFFFFF; }
  .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success:disabled, .btn-success:disabled:hover, .btn-success:disabled:focus, .btn-success:disabled.focus, .btn-success:disabled:active, .btn-success:disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    background-color: #44c47d;
    border-color: #44c47d; }
  .btn-success.btn-link {
    color: #6bd098; }
    .btn-success.btn-link:hover, .btn-success.btn-link:focus, .btn-success.btn-link:active, .btn-success.btn-link.active, .btn-success.btn-link:focus:active,
    .open > .btn-success.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #44c47d !important; }
    .btn-success.btn-link .caret {
      border-top-color: #6bd098; }
  .btn-success .caret {
    border-top-color: #FFFFFF; }

.btn-info {
  background-color: #51bcda;
  border-color: #51bcda;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info:active:focus, .btn-info:active:hover, .btn-info.active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle {
    background-color: #2ba9cd;
    color: #FFFFFF;
    border-color: #2ba9cd;
    box-shadow: none; }
  .btn-info .caret {
    border-top-color: #FFFFFF; }
  .btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info:disabled, .btn-info:disabled:hover, .btn-info:disabled:focus, .btn-info:disabled.focus, .btn-info:disabled:active, .btn-info:disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
  fieldset[disabled] .btn-info,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus,
  fieldset[disabled] .btn-info:active,
  fieldset[disabled] .btn-info.active {
    background-color: #2ba9cd;
    border-color: #2ba9cd; }
  .btn-info.btn-link {
    color: #51bcda; }
    .btn-info.btn-link:hover, .btn-info.btn-link:focus, .btn-info.btn-link:active, .btn-info.btn-link.active, .btn-info.btn-link:focus:active,
    .open > .btn-info.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #2ba9cd !important; }
    .btn-info.btn-link .caret {
      border-top-color: #51bcda; }
  .btn-info .caret {
    border-top-color: #FFFFFF; }

.btn-warning {
  background-color: #fbc658;
  border-color: #fbc658;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning:active:focus, .btn-warning:active:hover, .btn-warning.active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle {
    background-color: #fab526;
    color: #FFFFFF;
    border-color: #fab526;
    box-shadow: none; }
  .btn-warning .caret {
    border-top-color: #FFFFFF; }
  .btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning:disabled, .btn-warning:disabled:hover, .btn-warning:disabled:focus, .btn-warning:disabled.focus, .btn-warning:disabled:active, .btn-warning:disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    background-color: #fab526;
    border-color: #fab526; }
  .btn-warning.btn-link {
    color: #fbc658; }
    .btn-warning.btn-link:hover, .btn-warning.btn-link:focus, .btn-warning.btn-link:active, .btn-warning.btn-link.active, .btn-warning.btn-link:focus:active,
    .open > .btn-warning.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #fab526 !important; }
    .btn-warning.btn-link .caret {
      border-top-color: #fbc658; }
  .btn-warning .caret {
    border-top-color: #FFFFFF; }

.btn-danger {
  background-color: #f5593d;
  border-color: #f5593d;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle {
    background-color: #f33816;
    color: #FFFFFF;
    border-color: #f33816;
    box-shadow: none; }
  .btn-danger .caret {
    border-top-color: #FFFFFF; }
  .btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger:disabled, .btn-danger:disabled:hover, .btn-danger:disabled:focus, .btn-danger:disabled.focus, .btn-danger:disabled:active, .btn-danger:disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    background-color: #f33816;
    border-color: #f33816; }
  .btn-danger.btn-link {
    color: #f5593d; }
    .btn-danger.btn-link:hover, .btn-danger.btn-link:focus, .btn-danger.btn-link:active, .btn-danger.btn-link.active, .btn-danger.btn-link:focus:active,
    .open > .btn-danger.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #f33816 !important; }
    .btn-danger.btn-link .caret {
      border-top-color: #f5593d; }
  .btn-danger .caret {
    border-top-color: #FFFFFF; }

.btn-neutral {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .btn-neutral:active:focus, .btn-neutral:active:hover, .btn-neutral.active:focus, .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active, .btn-neutral:not(:disabled):not(.disabled).active:focus,
  .show > .btn-neutral.dropdown-toggle {
    background-color: #403D39;
    color: #FFFFFF;
    border-color: #403D39;
    box-shadow: none; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-neutral.disabled, .btn-neutral.disabled:hover, .btn-neutral.disabled:focus, .btn-neutral.disabled.focus, .btn-neutral.disabled:active, .btn-neutral.disabled.active, .btn-neutral:disabled, .btn-neutral:disabled:hover, .btn-neutral:disabled:focus, .btn-neutral:disabled.focus, .btn-neutral:disabled:active, .btn-neutral:disabled.active, .btn-neutral[disabled], .btn-neutral[disabled]:hover, .btn-neutral[disabled]:focus, .btn-neutral[disabled].focus, .btn-neutral[disabled]:active, .btn-neutral[disabled].active,
  fieldset[disabled] .btn-neutral,
  fieldset[disabled] .btn-neutral:hover,
  fieldset[disabled] .btn-neutral:focus,
  fieldset[disabled] .btn-neutral.focus,
  fieldset[disabled] .btn-neutral:active,
  fieldset[disabled] .btn-neutral.active {
    background-color: #403D39;
    border-color: #403D39; }
  .btn-neutral.btn-link {
    color: #FFFFFF; }
    .btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active, .btn-neutral.btn-link.active, .btn-neutral.btn-link:focus:active,
    .open > .btn-neutral.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #403D39 !important; }
    .btn-neutral.btn-link .caret {
      border-top-color: #FFFFFF; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }

.btn-outline-default {
  border-color: #66615B;
  color: #66615B;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-default:hover, .btn-outline-default:focus, .btn-outline-default:active, .btn-outline-default.active, .btn-outline-default:active:focus, .btn-outline-default:active:hover, .btn-outline-default.active:focus,
  .show > .btn-outline-default.dropdown-toggle {
    background-color: #66615B !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #66615B !important; }
    .btn-outline-default:hover .caret, .btn-outline-default:focus .caret, .btn-outline-default:active .caret, .btn-outline-default.active .caret, .btn-outline-default:active:focus .caret, .btn-outline-default:active:hover .caret, .btn-outline-default.active:focus .caret,
    .show > .btn-outline-default.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-default .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-default.disabled, .btn-outline-default.disabled:hover, .btn-outline-default.disabled:focus, .btn-outline-default.disabled.focus, .btn-outline-default.disabled:active, .btn-outline-default.disabled.active, .btn-outline-default:disabled, .btn-outline-default:disabled:hover, .btn-outline-default:disabled:focus, .btn-outline-default:disabled.focus, .btn-outline-default:disabled:active, .btn-outline-default:disabled.active, .btn-outline-default[disabled], .btn-outline-default[disabled]:hover, .btn-outline-default[disabled]:focus, .btn-outline-default[disabled].focus, .btn-outline-default[disabled]:active, .btn-outline-default[disabled].active,
  fieldset[disabled] .btn-outline-default,
  fieldset[disabled] .btn-outline-default:hover,
  fieldset[disabled] .btn-outline-default:focus,
  fieldset[disabled] .btn-outline-default.focus,
  fieldset[disabled] .btn-outline-default:active,
  fieldset[disabled] .btn-outline-default.active {
    background-color: transparent;
    border-color: #66615B; }

.btn-outline-primary {
  border-color: #51cbce;
  color: #51cbce;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary:active:focus, .btn-outline-primary:active:hover, .btn-outline-primary.active:focus,
  .show > .btn-outline-primary.dropdown-toggle {
    background-color: #51cbce !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #51cbce !important; }
    .btn-outline-primary:hover .caret, .btn-outline-primary:focus .caret, .btn-outline-primary:active .caret, .btn-outline-primary.active .caret, .btn-outline-primary:active:focus .caret, .btn-outline-primary:active:hover .caret, .btn-outline-primary.active:focus .caret,
    .show > .btn-outline-primary.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-primary .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-primary.disabled, .btn-outline-primary.disabled:hover, .btn-outline-primary.disabled:focus, .btn-outline-primary.disabled.focus, .btn-outline-primary.disabled:active, .btn-outline-primary.disabled.active, .btn-outline-primary:disabled, .btn-outline-primary:disabled:hover, .btn-outline-primary:disabled:focus, .btn-outline-primary:disabled.focus, .btn-outline-primary:disabled:active, .btn-outline-primary:disabled.active, .btn-outline-primary[disabled], .btn-outline-primary[disabled]:hover, .btn-outline-primary[disabled]:focus, .btn-outline-primary[disabled].focus, .btn-outline-primary[disabled]:active, .btn-outline-primary[disabled].active,
  fieldset[disabled] .btn-outline-primary,
  fieldset[disabled] .btn-outline-primary:hover,
  fieldset[disabled] .btn-outline-primary:focus,
  fieldset[disabled] .btn-outline-primary.focus,
  fieldset[disabled] .btn-outline-primary:active,
  fieldset[disabled] .btn-outline-primary.active {
    background-color: transparent;
    border-color: #51cbce; }

.btn-outline-success {
  border-color: #6bd098;
  color: #6bd098;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success:active:focus, .btn-outline-success:active:hover, .btn-outline-success.active:focus,
  .show > .btn-outline-success.dropdown-toggle {
    background-color: #6bd098 !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #6bd098 !important; }
    .btn-outline-success:hover .caret, .btn-outline-success:focus .caret, .btn-outline-success:active .caret, .btn-outline-success.active .caret, .btn-outline-success:active:focus .caret, .btn-outline-success:active:hover .caret, .btn-outline-success.active:focus .caret,
    .show > .btn-outline-success.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-success .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-success.disabled, .btn-outline-success.disabled:hover, .btn-outline-success.disabled:focus, .btn-outline-success.disabled.focus, .btn-outline-success.disabled:active, .btn-outline-success.disabled.active, .btn-outline-success:disabled, .btn-outline-success:disabled:hover, .btn-outline-success:disabled:focus, .btn-outline-success:disabled.focus, .btn-outline-success:disabled:active, .btn-outline-success:disabled.active, .btn-outline-success[disabled], .btn-outline-success[disabled]:hover, .btn-outline-success[disabled]:focus, .btn-outline-success[disabled].focus, .btn-outline-success[disabled]:active, .btn-outline-success[disabled].active,
  fieldset[disabled] .btn-outline-success,
  fieldset[disabled] .btn-outline-success:hover,
  fieldset[disabled] .btn-outline-success:focus,
  fieldset[disabled] .btn-outline-success.focus,
  fieldset[disabled] .btn-outline-success:active,
  fieldset[disabled] .btn-outline-success.active {
    background-color: transparent;
    border-color: #6bd098; }

.btn-outline-info {
  border-color: #51bcda;
  color: #51bcda;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info:active:focus, .btn-outline-info:active:hover, .btn-outline-info.active:focus,
  .show > .btn-outline-info.dropdown-toggle {
    background-color: #51bcda !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #51bcda !important; }
    .btn-outline-info:hover .caret, .btn-outline-info:focus .caret, .btn-outline-info:active .caret, .btn-outline-info.active .caret, .btn-outline-info:active:focus .caret, .btn-outline-info:active:hover .caret, .btn-outline-info.active:focus .caret,
    .show > .btn-outline-info.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-info .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-info.disabled, .btn-outline-info.disabled:hover, .btn-outline-info.disabled:focus, .btn-outline-info.disabled.focus, .btn-outline-info.disabled:active, .btn-outline-info.disabled.active, .btn-outline-info:disabled, .btn-outline-info:disabled:hover, .btn-outline-info:disabled:focus, .btn-outline-info:disabled.focus, .btn-outline-info:disabled:active, .btn-outline-info:disabled.active, .btn-outline-info[disabled], .btn-outline-info[disabled]:hover, .btn-outline-info[disabled]:focus, .btn-outline-info[disabled].focus, .btn-outline-info[disabled]:active, .btn-outline-info[disabled].active,
  fieldset[disabled] .btn-outline-info,
  fieldset[disabled] .btn-outline-info:hover,
  fieldset[disabled] .btn-outline-info:focus,
  fieldset[disabled] .btn-outline-info.focus,
  fieldset[disabled] .btn-outline-info:active,
  fieldset[disabled] .btn-outline-info.active {
    background-color: transparent;
    border-color: #51bcda; }

.btn-outline-warning {
  border-color: #fbc658;
  color: #fbc658;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning:active:focus, .btn-outline-warning:active:hover, .btn-outline-warning.active:focus,
  .show > .btn-outline-warning.dropdown-toggle {
    background-color: #fbc658 !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #fbc658 !important; }
    .btn-outline-warning:hover .caret, .btn-outline-warning:focus .caret, .btn-outline-warning:active .caret, .btn-outline-warning.active .caret, .btn-outline-warning:active:focus .caret, .btn-outline-warning:active:hover .caret, .btn-outline-warning.active:focus .caret,
    .show > .btn-outline-warning.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-warning .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-warning.disabled, .btn-outline-warning.disabled:hover, .btn-outline-warning.disabled:focus, .btn-outline-warning.disabled.focus, .btn-outline-warning.disabled:active, .btn-outline-warning.disabled.active, .btn-outline-warning:disabled, .btn-outline-warning:disabled:hover, .btn-outline-warning:disabled:focus, .btn-outline-warning:disabled.focus, .btn-outline-warning:disabled:active, .btn-outline-warning:disabled.active, .btn-outline-warning[disabled], .btn-outline-warning[disabled]:hover, .btn-outline-warning[disabled]:focus, .btn-outline-warning[disabled].focus, .btn-outline-warning[disabled]:active, .btn-outline-warning[disabled].active,
  fieldset[disabled] .btn-outline-warning,
  fieldset[disabled] .btn-outline-warning:hover,
  fieldset[disabled] .btn-outline-warning:focus,
  fieldset[disabled] .btn-outline-warning.focus,
  fieldset[disabled] .btn-outline-warning:active,
  fieldset[disabled] .btn-outline-warning.active {
    background-color: transparent;
    border-color: #fbc658; }

.btn-outline-danger {
  border-color: #f5593d;
  color: #f5593d;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger:active:focus, .btn-outline-danger:active:hover, .btn-outline-danger.active:focus,
  .show > .btn-outline-danger.dropdown-toggle {
    background-color: #f5593d !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #f5593d !important; }
    .btn-outline-danger:hover .caret, .btn-outline-danger:focus .caret, .btn-outline-danger:active .caret, .btn-outline-danger.active .caret, .btn-outline-danger:active:focus .caret, .btn-outline-danger:active:hover .caret, .btn-outline-danger.active:focus .caret,
    .show > .btn-outline-danger.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-danger .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-danger.disabled, .btn-outline-danger.disabled:hover, .btn-outline-danger.disabled:focus, .btn-outline-danger.disabled.focus, .btn-outline-danger.disabled:active, .btn-outline-danger.disabled.active, .btn-outline-danger:disabled, .btn-outline-danger:disabled:hover, .btn-outline-danger:disabled:focus, .btn-outline-danger:disabled.focus, .btn-outline-danger:disabled:active, .btn-outline-danger:disabled.active, .btn-outline-danger[disabled], .btn-outline-danger[disabled]:hover, .btn-outline-danger[disabled]:focus, .btn-outline-danger[disabled].focus, .btn-outline-danger[disabled]:active, .btn-outline-danger[disabled].active,
  fieldset[disabled] .btn-outline-danger,
  fieldset[disabled] .btn-outline-danger:hover,
  fieldset[disabled] .btn-outline-danger:focus,
  fieldset[disabled] .btn-outline-danger.focus,
  fieldset[disabled] .btn-outline-danger:active,
  fieldset[disabled] .btn-outline-danger.active {
    background-color: transparent;
    border-color: #f5593d; }

.btn-outline-neutral {
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-outline-neutral:hover, .btn-outline-neutral:focus, .btn-outline-neutral:active, .btn-outline-neutral.active, .btn-outline-neutral:active:focus, .btn-outline-neutral:active:hover, .btn-outline-neutral.active:focus,
  .show > .btn-outline-neutral.dropdown-toggle {
    background-color: #FFFFFF !important;
    color: rgba(255, 255, 255, 0.8);
    border-color: #FFFFFF !important; }
    .btn-outline-neutral:hover .caret, .btn-outline-neutral:focus .caret, .btn-outline-neutral:active .caret, .btn-outline-neutral.active .caret, .btn-outline-neutral:active:focus .caret, .btn-outline-neutral:active:hover .caret, .btn-outline-neutral.active:focus .caret,
    .show > .btn-outline-neutral.dropdown-toggle .caret {
      border-top-color: rgba(255, 255, 255, 0.8); }
  .btn-outline-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-outline-neutral.disabled, .btn-outline-neutral.disabled:hover, .btn-outline-neutral.disabled:focus, .btn-outline-neutral.disabled.focus, .btn-outline-neutral.disabled:active, .btn-outline-neutral.disabled.active, .btn-outline-neutral:disabled, .btn-outline-neutral:disabled:hover, .btn-outline-neutral:disabled:focus, .btn-outline-neutral:disabled.focus, .btn-outline-neutral:disabled:active, .btn-outline-neutral:disabled.active, .btn-outline-neutral[disabled], .btn-outline-neutral[disabled]:hover, .btn-outline-neutral[disabled]:focus, .btn-outline-neutral[disabled].focus, .btn-outline-neutral[disabled]:active, .btn-outline-neutral[disabled].active,
  fieldset[disabled] .btn-outline-neutral,
  fieldset[disabled] .btn-outline-neutral:hover,
  fieldset[disabled] .btn-outline-neutral:focus,
  fieldset[disabled] .btn-outline-neutral.focus,
  fieldset[disabled] .btn-outline-neutral:active,
  fieldset[disabled] .btn-outline-neutral.active {
    background-color: transparent;
    border-color: #FFFFFF; }
  .btn-outline-neutral:hover, .btn-outline-neutral:focus, .btn-outline-neutral:active {
    color: #403D39 !important;
    background-color: #FFFFFF; }

.btn-neutral {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #66615B; }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .btn-neutral:active:focus, .btn-neutral:active:hover, .btn-neutral.active:focus, .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active, .btn-neutral:not(:disabled):not(.disabled).active:focus,
  .show > .btn-neutral.dropdown-toggle {
    background-color: #FFFFFF;
    color: #FFFFFF;
    border-color: #FFFFFF;
    box-shadow: none; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-neutral.disabled, .btn-neutral.disabled:hover, .btn-neutral.disabled:focus, .btn-neutral.disabled.focus, .btn-neutral.disabled:active, .btn-neutral.disabled.active, .btn-neutral:disabled, .btn-neutral:disabled:hover, .btn-neutral:disabled:focus, .btn-neutral:disabled.focus, .btn-neutral:disabled:active, .btn-neutral:disabled.active, .btn-neutral[disabled], .btn-neutral[disabled]:hover, .btn-neutral[disabled]:focus, .btn-neutral[disabled].focus, .btn-neutral[disabled]:active, .btn-neutral[disabled].active,
  fieldset[disabled] .btn-neutral,
  fieldset[disabled] .btn-neutral:hover,
  fieldset[disabled] .btn-neutral:focus,
  fieldset[disabled] .btn-neutral.focus,
  fieldset[disabled] .btn-neutral:active,
  fieldset[disabled] .btn-neutral.active {
    background-color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-neutral.btn-link {
    color: #FFFFFF; }
    .btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active, .btn-neutral.btn-link.active, .btn-neutral.btn-link:focus:active,
    .open > .btn-neutral.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #FFFFFF !important; }
    .btn-neutral.btn-link .caret {
      border-top-color: #FFFFFF; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active {
    color: #403D39 !important; }
  .btn-neutral.btn-border:hover, .btn-neutral.btn-border:focus {
    color: #66615B; }
  .btn-neutral.btn-border:active, .btn-neutral.btn-border.active,
  .open > .btn-neutral.btn-border.dropdown-toggle {
    background-color: #FFFFFF;
    color: #66615B; }
  .btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active {
    color: #FFFFFF !important; }
  .btn-neutral.btn-link:focus:active {
    color: #403D39 !important; }
  .btn-neutral.btn-link:active, .btn-neutral.btn-link.active {
    background-color: transparent; }

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.btn-link {
  border-color: transparent !important;
  padding: 7px 18px; }
  .btn-link:hover, .btn-link:focus, .btn-link:active {
    text-decoration: none;
    border-color: transparent; }
  .btn-link.btn-icon {
    padding: 7px; }

.btn-lg {
  font-size: 14px;
  padding: 11px 30px; }
  .btn-lg.btn-simple {
    padding: 13px 30px; }

.btn-sm {
  font-size: 12px;
  padding: 4px 10px; }
  .btn-sm.btn-simple {
    padding: 6px 10px; }

.btn-wd {
  min-width: 140px; }

.btn-group.select {
  width: 100%; }

.btn-group.select .btn {
  text-align: left; }

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px; }

.btn-just-icon.btn-sm {
  height: 30px;
  width: 30px;
  min-width: 30px;
  padding: 0; }
  .btn-just-icon.btn-sm i {
    font-size: 12px;
    top: 1px !important; }

.btn-just-icon.btn-lg {
  height: 50px;
  width: 50px;
  min-width: 50px;
  padding: 13px; }
  .btn-just-icon.btn-lg i {
    font-size: 18px;
    padding: 0; }

.btn-just-icon.btn-link {
  padding: 8px !important; }

.btn-round {
  border-radius: 30px; }

.btn.btn-link:focus {
  box-shadow: none !important;
  text-decoration: none; }

.column .btn-link {
  padding: 7px 0; }

.share-buttons .btn-outline-default {
  margin-top: 24px; }

.btn-group.select {
  overflow: visible !important; }

.media .media-body .media-footer .btn-neutral {
  margin: 15px 3px;
  font-size: 14px; }
  .media .media-body .media-footer .btn-neutral i {
    margin-right: 0 !important; }

.btn-facebook {
  color: #FFFFFF;
  background-color: #3b5998;
  border-color: #3b5998;
  opacity: 0.8; }
  .btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active, .btn-facebook.active,
  .open > .btn-facebook.dropdown-toggle {
    background-color: #3b5998 !important;
    border-color: #3b5998 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-facebook.btn-border {
    border-color: #3b5998;
    color: #3b5998; }
    .btn-facebook.btn-border:hover, .btn-facebook.btn-border:focus, .btn-facebook.btn-border:active, .btn-facebook.btn-border.active,
    .open > .btn-facebook.btn-border.dropdown-toggle {
      background-color: #3b5998;
      border-color: #3b5998;
      color: rgba(255, 255, 255, 0.7); }
    .btn-facebook.btn-border:disabled, .btn-facebook.btn-border[disabled], .btn-facebook.btn-border.disabled {
      background-color: transparent;
      border-color: #3b5998; }
  .btn-facebook.btn-link {
    color: #3b5998;
    opacity: 0.8; }
    .btn-facebook.btn-link:hover, .btn-facebook.btn-link:focus, .btn-facebook.btn-link:active, .btn-facebook.btn-link.active,
    .open > .btn-facebook.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #3b5998;
      opacity: 1;
      border-color: transparent !important; }
      .btn-facebook.btn-link:hover i, .btn-facebook.btn-link:focus i, .btn-facebook.btn-link:active i, .btn-facebook.btn-link.active i,
      .open > .btn-facebook.btn-link.dropdown-toggle i {
        color: #3b5998;
        opacity: 1; }

.btn-twitter {
  color: #FFFFFF;
  background-color: #55acee;
  border-color: #55acee;
  opacity: 0.8; }
  .btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active, .btn-twitter.active,
  .open > .btn-twitter.dropdown-toggle {
    background-color: #55acee !important;
    border-color: #55acee !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-twitter.btn-border {
    border-color: #55acee;
    color: #55acee; }
    .btn-twitter.btn-border:hover, .btn-twitter.btn-border:focus, .btn-twitter.btn-border:active, .btn-twitter.btn-border.active,
    .open > .btn-twitter.btn-border.dropdown-toggle {
      background-color: #55acee;
      border-color: #55acee;
      color: rgba(255, 255, 255, 0.7); }
    .btn-twitter.btn-border:disabled, .btn-twitter.btn-border[disabled], .btn-twitter.btn-border.disabled {
      background-color: transparent;
      border-color: #55acee; }
  .btn-twitter.btn-link {
    color: #55acee;
    opacity: 0.8; }
    .btn-twitter.btn-link:hover, .btn-twitter.btn-link:focus, .btn-twitter.btn-link:active, .btn-twitter.btn-link.active,
    .open > .btn-twitter.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #55acee;
      opacity: 1;
      border-color: transparent !important; }
      .btn-twitter.btn-link:hover i, .btn-twitter.btn-link:focus i, .btn-twitter.btn-link:active i, .btn-twitter.btn-link.active i,
      .open > .btn-twitter.btn-link.dropdown-toggle i {
        color: #55acee;
        opacity: 1; }

.btn-pinterest {
  color: #FFFFFF;
  background-color: #cc2127;
  border-color: #cc2127;
  opacity: 0.8; }
  .btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active, .btn-pinterest.active,
  .open > .btn-pinterest.dropdown-toggle {
    background-color: #cc2127 !important;
    border-color: #cc2127 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-pinterest.btn-border {
    border-color: #cc2127;
    color: #cc2127; }
    .btn-pinterest.btn-border:hover, .btn-pinterest.btn-border:focus, .btn-pinterest.btn-border:active, .btn-pinterest.btn-border.active,
    .open > .btn-pinterest.btn-border.dropdown-toggle {
      background-color: #cc2127;
      border-color: #cc2127;
      color: rgba(255, 255, 255, 0.7); }
    .btn-pinterest.btn-border:disabled, .btn-pinterest.btn-border[disabled], .btn-pinterest.btn-border.disabled {
      background-color: transparent;
      border-color: #cc2127; }
  .btn-pinterest.btn-link {
    color: #cc2127;
    opacity: 0.8; }
    .btn-pinterest.btn-link:hover, .btn-pinterest.btn-link:focus, .btn-pinterest.btn-link:active, .btn-pinterest.btn-link.active,
    .open > .btn-pinterest.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #cc2127;
      opacity: 1;
      border-color: transparent !important; }
      .btn-pinterest.btn-link:hover i, .btn-pinterest.btn-link:focus i, .btn-pinterest.btn-link:active i, .btn-pinterest.btn-link.active i,
      .open > .btn-pinterest.btn-link.dropdown-toggle i {
        color: #cc2127;
        opacity: 1; }

.btn-google {
  color: #FFFFFF;
  background-color: #dd4b39;
  border-color: #dd4b39;
  opacity: 0.8; }
  .btn-google:hover, .btn-google:focus, .btn-google:active, .btn-google.active,
  .open > .btn-google.dropdown-toggle {
    background-color: #dd4b39 !important;
    border-color: #dd4b39 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-google.btn-border {
    border-color: #dd4b39;
    color: #dd4b39; }
    .btn-google.btn-border:hover, .btn-google.btn-border:focus, .btn-google.btn-border:active, .btn-google.btn-border.active,
    .open > .btn-google.btn-border.dropdown-toggle {
      background-color: #dd4b39;
      border-color: #dd4b39;
      color: rgba(255, 255, 255, 0.7); }
    .btn-google.btn-border:disabled, .btn-google.btn-border[disabled], .btn-google.btn-border.disabled {
      background-color: transparent;
      border-color: #dd4b39; }
  .btn-google.btn-link {
    color: #dd4b39;
    opacity: 0.8; }
    .btn-google.btn-link:hover, .btn-google.btn-link:focus, .btn-google.btn-link:active, .btn-google.btn-link.active,
    .open > .btn-google.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #dd4b39;
      opacity: 1;
      border-color: transparent !important; }
      .btn-google.btn-link:hover i, .btn-google.btn-link:focus i, .btn-google.btn-link:active i, .btn-google.btn-link.active i,
      .open > .btn-google.btn-link.dropdown-toggle i {
        color: #dd4b39;
        opacity: 1; }

.btn-linkedin {
  color: #FFFFFF;
  background-color: #0976b4;
  border-color: #0976b4;
  opacity: 0.8; }
  .btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active, .btn-linkedin.active,
  .open > .btn-linkedin.dropdown-toggle {
    background-color: #0976b4 !important;
    border-color: #0976b4 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-linkedin.btn-border {
    border-color: #0976b4;
    color: #0976b4; }
    .btn-linkedin.btn-border:hover, .btn-linkedin.btn-border:focus, .btn-linkedin.btn-border:active, .btn-linkedin.btn-border.active,
    .open > .btn-linkedin.btn-border.dropdown-toggle {
      background-color: #0976b4;
      border-color: #0976b4;
      color: rgba(255, 255, 255, 0.7); }
    .btn-linkedin.btn-border:disabled, .btn-linkedin.btn-border[disabled], .btn-linkedin.btn-border.disabled {
      background-color: transparent;
      border-color: #0976b4; }
  .btn-linkedin.btn-link {
    color: #0976b4;
    opacity: 0.8; }
    .btn-linkedin.btn-link:hover, .btn-linkedin.btn-link:focus, .btn-linkedin.btn-link:active, .btn-linkedin.btn-link.active,
    .open > .btn-linkedin.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #0976b4;
      opacity: 1;
      border-color: transparent !important; }
      .btn-linkedin.btn-link:hover i, .btn-linkedin.btn-link:focus i, .btn-linkedin.btn-link:active i, .btn-linkedin.btn-link.active i,
      .open > .btn-linkedin.btn-link.dropdown-toggle i {
        color: #0976b4;
        opacity: 1; }

.btn-dribbble {
  color: #FFFFFF;
  background-color: #ea4c89;
  border-color: #ea4c89;
  opacity: 0.8; }
  .btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active, .btn-dribbble.active,
  .open > .btn-dribbble.dropdown-toggle {
    background-color: #ea4c89 !important;
    border-color: #ea4c89 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-dribbble.btn-border {
    border-color: #ea4c89;
    color: #ea4c89; }
    .btn-dribbble.btn-border:hover, .btn-dribbble.btn-border:focus, .btn-dribbble.btn-border:active, .btn-dribbble.btn-border.active,
    .open > .btn-dribbble.btn-border.dropdown-toggle {
      background-color: #ea4c89;
      border-color: #ea4c89;
      color: rgba(255, 255, 255, 0.7); }
    .btn-dribbble.btn-border:disabled, .btn-dribbble.btn-border[disabled], .btn-dribbble.btn-border.disabled {
      background-color: transparent;
      border-color: #ea4c89; }
  .btn-dribbble.btn-link {
    color: #ea4c89;
    opacity: 0.8; }
    .btn-dribbble.btn-link:hover, .btn-dribbble.btn-link:focus, .btn-dribbble.btn-link:active, .btn-dribbble.btn-link.active,
    .open > .btn-dribbble.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #ea4c89;
      opacity: 1;
      border-color: transparent !important; }
      .btn-dribbble.btn-link:hover i, .btn-dribbble.btn-link:focus i, .btn-dribbble.btn-link:active i, .btn-dribbble.btn-link.active i,
      .open > .btn-dribbble.btn-link.dropdown-toggle i {
        color: #ea4c89;
        opacity: 1; }

.btn-github {
  color: #FFFFFF;
  background-color: #333333;
  border-color: #333333;
  opacity: 0.8; }
  .btn-github:hover, .btn-github:focus, .btn-github:active, .btn-github.active,
  .open > .btn-github.dropdown-toggle {
    background-color: #333333 !important;
    border-color: #333333 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-github.btn-border {
    border-color: #333333;
    color: #333333; }
    .btn-github.btn-border:hover, .btn-github.btn-border:focus, .btn-github.btn-border:active, .btn-github.btn-border.active,
    .open > .btn-github.btn-border.dropdown-toggle {
      background-color: #333333;
      border-color: #333333;
      color: rgba(255, 255, 255, 0.7); }
    .btn-github.btn-border:disabled, .btn-github.btn-border[disabled], .btn-github.btn-border.disabled {
      background-color: transparent;
      border-color: #333333; }
  .btn-github.btn-link {
    color: #333333;
    opacity: 0.8; }
    .btn-github.btn-link:hover, .btn-github.btn-link:focus, .btn-github.btn-link:active, .btn-github.btn-link.active,
    .open > .btn-github.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #333333;
      opacity: 1;
      border-color: transparent !important; }
      .btn-github.btn-link:hover i, .btn-github.btn-link:focus i, .btn-github.btn-link:active i, .btn-github.btn-link.active i,
      .open > .btn-github.btn-link.dropdown-toggle i {
        color: #333333;
        opacity: 1; }

.btn-youtube {
  color: #FFFFFF;
  background-color: #e52d27;
  border-color: #e52d27;
  opacity: 0.8; }
  .btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active, .btn-youtube.active,
  .open > .btn-youtube.dropdown-toggle {
    background-color: #e52d27 !important;
    border-color: #e52d27 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-youtube.btn-border {
    border-color: #e52d27;
    color: #e52d27; }
    .btn-youtube.btn-border:hover, .btn-youtube.btn-border:focus, .btn-youtube.btn-border:active, .btn-youtube.btn-border.active,
    .open > .btn-youtube.btn-border.dropdown-toggle {
      background-color: #e52d27;
      border-color: #e52d27;
      color: rgba(255, 255, 255, 0.7); }
    .btn-youtube.btn-border:disabled, .btn-youtube.btn-border[disabled], .btn-youtube.btn-border.disabled {
      background-color: transparent;
      border-color: #e52d27; }
  .btn-youtube.btn-link {
    color: #e52d27;
    opacity: 0.8; }
    .btn-youtube.btn-link:hover, .btn-youtube.btn-link:focus, .btn-youtube.btn-link:active, .btn-youtube.btn-link.active,
    .open > .btn-youtube.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #e52d27;
      opacity: 1;
      border-color: transparent !important; }
      .btn-youtube.btn-link:hover i, .btn-youtube.btn-link:focus i, .btn-youtube.btn-link:active i, .btn-youtube.btn-link.active i,
      .open > .btn-youtube.btn-link.dropdown-toggle i {
        color: #e52d27;
        opacity: 1; }

.btn-instagram {
  color: #FFFFFF;
  background-color: #125688;
  border-color: #125688;
  opacity: 0.8; }
  .btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active, .btn-instagram.active,
  .open > .btn-instagram.dropdown-toggle {
    background-color: #125688 !important;
    border-color: #125688 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-instagram.btn-border {
    border-color: #125688;
    color: #125688; }
    .btn-instagram.btn-border:hover, .btn-instagram.btn-border:focus, .btn-instagram.btn-border:active, .btn-instagram.btn-border.active,
    .open > .btn-instagram.btn-border.dropdown-toggle {
      background-color: #125688;
      border-color: #125688;
      color: rgba(255, 255, 255, 0.7); }
    .btn-instagram.btn-border:disabled, .btn-instagram.btn-border[disabled], .btn-instagram.btn-border.disabled {
      background-color: transparent;
      border-color: #125688; }
  .btn-instagram.btn-link {
    color: #125688;
    opacity: 0.8; }
    .btn-instagram.btn-link:hover, .btn-instagram.btn-link:focus, .btn-instagram.btn-link:active, .btn-instagram.btn-link.active,
    .open > .btn-instagram.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #125688;
      opacity: 1;
      border-color: transparent !important; }
      .btn-instagram.btn-link:hover i, .btn-instagram.btn-link:focus i, .btn-instagram.btn-link:active i, .btn-instagram.btn-link.active i,
      .open > .btn-instagram.btn-link.dropdown-toggle i {
        color: #125688;
        opacity: 1; }

.btn-reddit {
  color: #FFFFFF;
  background-color: #ff4500;
  border-color: #ff4500;
  opacity: 0.8; }
  .btn-reddit:hover, .btn-reddit:focus, .btn-reddit:active, .btn-reddit.active,
  .open > .btn-reddit.dropdown-toggle {
    background-color: #ff4500 !important;
    border-color: #ff4500 !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-reddit.btn-border {
    border-color: #ff4500;
    color: #ff4500; }
    .btn-reddit.btn-border:hover, .btn-reddit.btn-border:focus, .btn-reddit.btn-border:active, .btn-reddit.btn-border.active,
    .open > .btn-reddit.btn-border.dropdown-toggle {
      background-color: #ff4500;
      border-color: #ff4500;
      color: rgba(255, 255, 255, 0.7); }
    .btn-reddit.btn-border:disabled, .btn-reddit.btn-border[disabled], .btn-reddit.btn-border.disabled {
      background-color: transparent;
      border-color: #ff4500; }
  .btn-reddit.btn-link {
    color: #ff4500;
    opacity: 0.8; }
    .btn-reddit.btn-link:hover, .btn-reddit.btn-link:focus, .btn-reddit.btn-link:active, .btn-reddit.btn-link.active,
    .open > .btn-reddit.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #ff4500;
      opacity: 1;
      border-color: transparent !important; }
      .btn-reddit.btn-link:hover i, .btn-reddit.btn-link:focus i, .btn-reddit.btn-link:active i, .btn-reddit.btn-link.active i,
      .open > .btn-reddit.btn-link.dropdown-toggle i {
        color: #ff4500;
        opacity: 1; }

.btn-tumblr {
  color: #FFFFFF;
  background-color: #35465c;
  border-color: #35465c;
  opacity: 0.8; }
  .btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active, .btn-tumblr.active,
  .open > .btn-tumblr.dropdown-toggle {
    background-color: #35465c !important;
    border-color: #35465c !important;
    color: #FFFFFF;
    opacity: 1; }
  .btn-tumblr.btn-border {
    border-color: #35465c;
    color: #35465c; }
    .btn-tumblr.btn-border:hover, .btn-tumblr.btn-border:focus, .btn-tumblr.btn-border:active, .btn-tumblr.btn-border.active,
    .open > .btn-tumblr.btn-border.dropdown-toggle {
      background-color: #35465c;
      border-color: #35465c;
      color: rgba(255, 255, 255, 0.7); }
    .btn-tumblr.btn-border:disabled, .btn-tumblr.btn-border[disabled], .btn-tumblr.btn-border.disabled {
      background-color: transparent;
      border-color: #35465c; }
  .btn-tumblr.btn-link {
    color: #35465c;
    opacity: 0.8; }
    .btn-tumblr.btn-link:hover, .btn-tumblr.btn-link:focus, .btn-tumblr.btn-link:active, .btn-tumblr.btn-link.active,
    .open > .btn-tumblr.btn-link.dropdown-toggle {
      background-color: transparent !important;
      color: #35465c;
      opacity: 1;
      border-color: transparent !important; }
      .btn-tumblr.btn-link:hover i, .btn-tumblr.btn-link:focus i, .btn-tumblr.btn-link:active i, .btn-tumblr.btn-link.active i,
      .open > .btn-tumblr.btn-link.dropdown-toggle i {
        color: #35465c;
        opacity: 1; }

.label-facebook {
  background-color: #3b5998; }

.label-twitter {
  background-color: #55acee; }

.label-pinterest {
  background-color: #cc2127; }

.label-google {
  background-color: #dd4b39; }

.label-linkedin {
  background-color: #0976b4; }

.label-dribbble {
  background-color: #ea4c89; }

.label-github {
  background-color: #333333; }

.label-youtube {
  background-color: #e52d27; }

.label-instagram {
  background-color: #125688; }

.label-reddit {
  background-color: #ff4500; }

.label-tumblr {
  background-color: #35465c; }

.icon-facebook {
  color: #3b5998; }

.icon-twitter {
  color: #55acee; }

.icon-pinterest {
  color: #cc2127; }

.icon-google {
  color: #dd4b39; }

.icon-linkedin {
  color: #0976b4; }

.icon-dribbble {
  color: #ea4c89; }

.icon-github {
  color: #333333; }

.icon-youtube {
  color: #e52d27; }

.icon-instagram {
  color: #125688; }

.icon-reddit {
  color: #ff4500; }

.icon-tumblr {
  color: #35465c; }

::-moz-placeholder {
  color: #9A9A9A; }

:-ms-input-placeholder {
  color: #9A9A9A; }

::-webkit-input-placeholder {
  color: #9A9A9A; }

.form-control:focus + .input-group-text {
  border: 1px solid black; }

.form-control {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #66615b;
  font-size: 14px;
  transition: background-color 0.3s ease 0s;
  padding-right: 0 !important;
  padding: 7px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .form-control:focus {
    background-color: #FFFFFF;
    border: 1px solid #ccc;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }
    .form-control:focus + .input-group-prepend .input-group-text,
    .form-control:focus + .input-group-append .input-group-text {
      border: 1px solid #ccc;
      border-left: none; }
  .form-control.no-border {
    border: medium none !important; }
    .form-control.no-border:focus + .input-group-prepend .input-group-text,
    .form-control.no-border:focus + .input-group-append .input-group-text {
      border: none; }
  .has-success .form-control,
  .has-error .form-control,
  .has-success .form-control:focus,
  .has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .has-success .form-control {
    border: 1px solid #ccc;
    color: #66615b; }
    .has-success .form-control.form-control-success {
      padding-right: 2.5em !important; }
  .has-success .form-control:focus {
    border: 1px solid #6bd098;
    color: #6bd098; }
  .has-danger .form-control {
    background-color: #FFC0A4;
    border: 1px solid #f5593d;
    color: #f5593d; }
    .has-danger .form-control.form-control-danger {
      padding-right: 2.5em !important; }
  .has-danger .form-control:focus {
    background-color: #FFFFFF;
    border: 1px solid #f5593d; }
  .form-control + .form-control-feedback {
    border-radius: 6px;
    font-size: 14px;
    color: #f5593d;
    font-size: .8rem;
    position: absolute;
    top: 100%;
    padding-left: 12px;
    vertical-align: middle; }
  .open .form-control {
    border-radius: 4px 4px 0 0;
    border-bottom-color: transparent; }
  .form-control.form-control-lg {
    height: 55px;
    padding: 11px 13px;
    font-size: 1.25rem;
    line-height: 1.5; }
  .form-control.form-control-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    height: 31px; }

.has-error .form-control-feedback, .has-error .control-label {
  color: #f5593d; }

.has-success .form-control-feedback, .has-success .control-label {
  color: #6bd098; }

.input-group-text {
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #DDDDDD; }
  .has-success .input-group-text,
  .has-error .input-group-text {
    background-color: #FFFFFF; }
  .has-error .form-control:focus + .input-group-text {
    color: #f5593d; }
  .has-success .form-control:focus + .input-group-text {
    color: #6bd098; }
  .form-control:focus + .input-group-text,
  .form-control:focus ~ .input-group-text {
    background-color: #FFFFFF; }

.input-group.no-border .input-group-text {
  border: 0; }

.input-group .form-control:first-child,
.input-group:not(.input-group-text):first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none; }

.input-group .form-control:last-child,
.input-group-text:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none; }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #E3E3E3;
  color: #66615B;
  cursor: not-allowed; }

.input-group[disabled] .input-group-text {
  background-color: #E3E3E3;
  cursor: not-allowed;
  border-color: #DDDDDD; }

.input-group-btn .btn {
  border-width: 1px;
  padding: 9px 18px; }

.input-group-btn .btn-default:not(.btn-fill) {
  border-color: #DDDDDD; }

.input-group-btn:last-child > .btn {
  margin-left: 0; }

textarea.form-control {
  padding: 10px 18px;
  height: auto; }

.form-group {
  position: relative; }

.register-form .form-control {
  border: 1px solid transparent !important; }

#inputs .input-group {
  margin-bottom: 1rem; }

.card-form-horizontal .card-body .form-group {
  margin-bottom: 0; }

/*             Navigation menu                */
/*             Navigation Tabs                 */
.nav-tabs-navigation {
  text-align: center;
  border-bottom: 1px solid #F1EAE0;
  margin-bottom: 30px; }
  .nav-tabs-navigation .nav > .nav-item > .nav-link {
    padding-bottom: 20px; }

.nav-tabs-wrapper {
  display: inline-block;
  margin-bottom: -6px;
  margin-left: 1.25%;
  margin-right: 1.25%;
  position: relative;
  width: auto; }

.nav-tabs {
  border-bottom: 0 none;
  font-size: 16px;
  font-weight: 600; }
  .nav-tabs .nav-item .nav-link {
    border: 0 none;
    color: #A49E93;
    background-color: transparent; }
  .nav-tabs .nav-item .nav-link:hover {
    color: #66615b; }
  .nav-tabs .nav-item .nav-link.active {
    color: #66615b; }
  .nav-tabs .nav-item {
    color: #66615b;
    position: relative; }
    .nav-tabs .nav-item .nav-link.active,
    .nav-tabs .nav-item .nav-link.active:hover,
    .nav-tabs .nav-item .nav-link.active:focus {
      background-color: transparent;
      border: 0 none; }
      .nav-tabs .nav-item .nav-link.active:after,
      .nav-tabs .nav-item .nav-link.active:hover:after,
      .nav-tabs .nav-item .nav-link.active:focus:after {
        border-bottom: 11px solid #FFFFFF;
        border-left: 11px solid rgba(0, 0, 0, 0);
        border-right: 11px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        position: absolute;
        right: 40%;
        bottom: 5px; }
      .nav-tabs .nav-item .nav-link.active:before,
      .nav-tabs .nav-item .nav-link.active:hover:before,
      .nav-tabs .nav-item .nav-link.active:focus:before {
        border-bottom: 11px solid #F1EAE0;
        border-left: 11px solid rgba(0, 0, 0, 0);
        border-right: 11px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        position: absolute;
        right: 40%;
        bottom: 6px; }
  .nav-tabs .nav-item.show .nav-link {
    background-color: transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: -6px;
    margin-left: -46px;
    border-radius: 8px; }
    .nav-tabs .dropdown-menu .dropdown-item:hover,
    .nav-tabs .dropdown-menu .dropdown-item.active {
      color: #FFFFFF;
      background-color: #68B3C8; }
    .nav-tabs .dropdown-menu :before {
      border-bottom: 11px solid #F1EAE0;
      border-left: 11px solid rgba(0, 0, 0, 0);
      border-right: 11px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      right: 12px;
      top: -11px; }
    .nav-tabs .dropdown-menu :after {
      border-bottom: 11px solid #FFFCF5;
      border-left: 11px solid rgba(0, 0, 0, 0);
      border-right: 11px solid rgba(0, 0, 0, 0);
      content: "";
      display: inline-block;
      position: absolute;
      right: 12px;
      top: -10px; }

.profile-content .tab-content .tab-pane {
  min-height: 200px; }

.profile-content .tab-content #tweets, .profile-content .tab-content #connections, .profile-content .tab-content #media {
  height: 100%; }

/*             Navigation Pills               */
.nav-pills .nav-item + .nav-link {
  margin-left: 0; }

.nav-pills .nav-item .nav-link {
  border: 1px solid #66615B;
  border-radius: 0;
  color: #66615B;
  font-weight: 600;
  margin-left: -1px;
  padding: 10px 25px; }

.nav-pills .nav-item.active .nav-link,
.nav-pills .nav-item.active .nav-link:hover,
.nav-pills .nav-item.active .nav-link:focus {
  background-color: #66615B;
  color: #FFFFFF; }

.nav-pills .nav-item:first-child .nav-link {
  border-radius: 30px 0 0 30px !important;
  margin: 0; }

.nav-pills .nav-item:last-child .nav-link {
  border-radius: 0 30px 30px 0 !important; }

.nav-pills .nav-item .nav-link.active {
  background-color: #66615B;
  color: #FFFFFF; }

.nav-pills-primary .nav-item .nav-link {
  border: 1px solid #51cbce !important;
  color: #51cbce !important; }
  .nav-pills-primary .nav-item .nav-link.active {
    border: 1px solid #51cbce !important;
    color: #FFFFFF !important; }

.nav-pills-danger .nav-item .nav-link {
  border: 1px solid #f5593d !important;
  color: #f5593d !important; }
  .nav-pills-danger .nav-item .nav-link.active {
    border: 1px solid #f5593d !important;
    color: #FFFFFF !important; }

.nav-pills-info .nav-item .nav-link {
  border: 1px solid #51bcda !important;
  color: #51bcda !important; }
  .nav-pills-info .nav-item .nav-link.active {
    border: 1px solid #51bcda !important;
    color: #FFFFFF !important; }

.nav-pills-success .nav-item .nav-link {
  border: 1px solid #6bd098 !important;
  color: #6bd098 !important; }
  .nav-pills-success .nav-item .nav-link.active {
    border: 1px solid #6bd098 !important;
    color: #FFFFFF !important; }

.nav-pills-warning .nav-item .nav-link {
  border: 1px solid #fbc658 !important;
  color: #fbc658 !important; }
  .nav-pills-warning .nav-item .nav-link.active {
    border: 1px solid #fbc658 !important;
    color: #FFFFFF !important; }

.nav .nav-item .nav-link:hover,
.nav .nav-item .nav-link:focus {
  background-color: transparent; }

.navbar {
  border: 0;
  font-size: 14px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15); }
  .navbar .navbar-brand {
    font-weight: 600;
    margin: 5px 0px;
    padding: 20px 15px;
    font-size: 14px;
    color: #66615B;
    text-transform: uppercase; }
  .navbar .navbar-nav .nav-item .nav-link {
    line-height: 1.6;
    margin: 15px 3px;
    padding: 10px 15px;
    opacity: .8;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #66615B; }
  .navbar .navbar-nav .nav-item .nav-link.btn {
    margin: 15px 3px;
    padding: 9px; }
  .navbar .navbar-nav .nav-item .nav-link [class^="fa"] {
    font-size: 17px;
    position: relative;
    margin: 0px -5px;
    right: 5px; }
  .navbar .navbar-nav .dropdown-menu {
    border-radius: 12px;
    margin-top: 1px; }
  .navbar .navbar-nav .nav-item .btn i {
    color: #FFFFFF; }
  .navbar .navbar-collapse .nav-item .nav-link p {
    display: inline; }
  .navbar .navbar-collapse .nav-item .dropdown-item i {
    margin: 0 10px;
    margin: 0 10px 0px 5px;
    font-size: 18px;
    position: relative;
    top: 3px; }
  .navbar .navbar-collapse.show .navbar-nav .nav-item {
    padding-right: 10px; }
  .navbar #navbarSupportedContent .nav-item {
    position: relative; }
  .navbar .notification-bubble {
    padding: 0.4em 0.6em;
    position: absolute;
    top: 10px;
    right: -2px; }
  .navbar .btn {
    margin: 14px 3px;
    font-size: 12px; }
    .navbar .btn i {
      font-size: 14px;
      position: relative;
      top: 2px; }
  .navbar .btn-simple {
    font-size: 16px; }
  .navbar .caret {
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    position: absolute; }
  .navbar.navbar-transparent {
    padding-top: 25px; }
  .navbar .logo-container {
    margin-top: 5px; }
    .navbar .logo-container .logo {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #333333;
      width: 50px;
      float: left; }
      .navbar .logo-container .logo img {
        width: 100%; }
    .navbar .logo-container .brand {
      font-size: 18px;
      color: #FFFFFF;
      line-height: 20px;
      float: left;
      margin-left: 10px;
      margin-top: 5px;
      width: 75px;
      height: 50px; }

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029; }

.bd-docs .navigation-example .navbar.navbar-transparent {
  padding-top: 0;
  padding: 20px 0;
  margin-top: 20px; }

.nav .nav-item .nav-link:hover,
.nav .nav-item .nav-link:focus {
  background-color: transparent; }

.navbar {
  border: 0;
  font-size: 14px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15); }
  .navbar .navbar-brand {
    font-weight: 600;
    margin: 5px 0px;
    padding: 20px 15px;
    font-size: 14px;
    color: #66615B;
    text-transform: uppercase; }
  .navbar .navbar-nav .nav-item .nav-link {
    line-height: 1.6;
    margin: 15px 3px;
    padding: 10px 15px;
    opacity: .8;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #66615B; }
  .navbar .navbar-nav .nav-item .nav-link.btn {
    margin: 15px 3px;
    padding: 9px; }
  .navbar .navbar-nav .nav-item .nav-link [class^="fa"] {
    font-size: 17px;
    position: relative;
    margin: 0px -5px;
    right: 5px; }
  .navbar .navbar-nav .dropdown-menu {
    border-radius: 12px;
    margin-top: 1px; }
  .navbar .navbar-nav .nav-item .btn i {
    color: #FFFFFF; }
  .navbar .navbar-collapse .nav-item .nav-link p {
    display: inline; }
  .navbar .navbar-collapse .nav-item .dropdown-item i {
    margin: 0 10px;
    margin: 0 10px 0px 5px;
    font-size: 18px;
    position: relative;
    top: 3px; }
  .navbar .navbar-collapse.show .navbar-nav .nav-item {
    padding-right: 10px; }
  .navbar #navbarSupportedContent .nav-item {
    position: relative; }
  .navbar .notification-bubble {
    padding: 0.4em 0.6em;
    position: absolute;
    top: 10px;
    right: -2px; }
  .navbar .btn {
    margin: 14px 3px;
    font-size: 12px; }
    .navbar .btn i {
      font-size: 14px;
      position: relative;
      top: 2px; }
  .navbar .btn-simple {
    font-size: 16px; }
  .navbar .caret {
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    position: absolute; }
  .navbar.navbar-transparent {
    padding-top: 25px; }
  .navbar .logo-container {
    margin-top: 5px; }
    .navbar .logo-container .logo {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #333333;
      width: 50px;
      float: left; }
      .navbar .logo-container .logo img {
        width: 100%; }
    .navbar .logo-container .brand {
      font-size: 18px;
      color: #FFFFFF;
      line-height: 20px;
      float: left;
      margin-left: 10px;
      margin-top: 5px;
      width: 75px;
      height: 50px; }

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029; }

.bd-docs .navigation-example .navbar.navbar-transparent {
  padding-top: 0;
  padding: 20px 0;
  margin-top: 20px; }

.navbar-transparent .navbar-brand, [class*="bg"] .navbar-brand {
  color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }
  .navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover, [class*="bg"] .navbar-brand:focus, [class*="bg"] .navbar-brand:hover {
    background-color: transparent;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #FFFFFF; }

.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn), [class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #FFFFFF;
  border-color: #FFFFFF; }

.navbar-transparent .navbar-nav .active .nav-link .active .nav-link:hover,
.navbar-transparent .navbar-nav .active .nav-link:focus,
.navbar-transparent .navbar-nav .nav-item .nav-link:hover,
.navbar-transparent .navbar-nav .nav-item .nav-link:focus, [class*="bg"] .navbar-nav .active .nav-link .active .nav-link:hover,
[class*="bg"] .navbar-nav .active .nav-link:focus,
[class*="bg"] .navbar-nav .nav-item .nav-link:hover,
[class*="bg"] .navbar-nav .nav-item .nav-link:focus {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav .nav .nav-item a.btn:hover, [class*="bg"] .navbar-nav .nav .nav-item a.btn:hover {
  background-color: transparent; }

.navbar-transparent .navbar-nav .dropdown .nav-link .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:hover .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:focus .caret, [class*="bg"] .navbar-nav .dropdown .nav-link .caret,
[class*="bg"] .navbar-nav .dropdown .nav-link:hover .caret,
[class*="bg"] .navbar-nav .dropdown .nav-link:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF; }

.navbar-transparent .navbar-nav .open .nav-link,
.navbar-transparent .navbar-nav .open .nav-link:hover,
.navbar-transparent .navbar-nav .open .nav-link:focus, [class*="bg"] .navbar-nav .open .nav-link,
[class*="bg"] .navbar-nav .open .nav-link:hover,
[class*="bg"] .navbar-nav .open .nav-link:focus {
  background-color: transparent;
  color: #66615B;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .btn-default.btn-fill, [class*="bg"] .btn-default.btn-fill {
  color: #9A9A9A;
  background-color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }

.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default, [class*="bg"] .btn-default.btn-fill:hover,
[class*="bg"] .btn-default.btn-fill:focus,
[class*="bg"] .btn-default.btn-fill:active,
[class*="bg"] .btn-default.btn-fill.active,
[class*="bg"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.nav-open .nav .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF; }

.navbar-default .brand {
  color: #66615b !important; }

.navbar-default .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #9A9A9A; }

.navbar-default .navbar-nav .active .nav-link,
.navbar-default .navbar-nav .active .nav-link:not(.btn):hover,
.navbar-default .navbar-nav .active .nav-link:not(.btn):focus,
.navbar-default .navbar-nav .nav-item .nav-link:not(.btn):hover,
.navbar-default .navbar-nav .nav-item .nav-link:not(.btn):focus {
  background-color: transparent;
  border-radius: 3px;
  color: #51bcda;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-default .navbar-nav .dropdown .nav-link:hover .caret,
.navbar-default .navbar-nav .dropdown .nav-link:focus .caret {
  border-bottom-color: #51bcda;
  border-top-color: #51bcda; }

.navbar-default .navbar-nav .open .nav-link,
.navbar-default .navbar-nav .open .nav-link:hover,
.navbar-default .navbar-nav .open .nav-link:focus {
  background-color: transparent;
  color: #51bcda; }

.navbar-default .navbar-nav .navbar-toggle:hover, .navbar-default .navbar-nav .navbar-toggle:focus {
  background-color: transparent; }

.navbar-default:not(.navbar-transparent) .btn-default:hover {
  color: #51bcda;
  border-color: #51bcda; }

.navbar-default:not(.navbar-transparent) .btn-neutral,
.navbar-default:not(.navbar-transparent) .btn-neutral:hover,
.navbar-default:not(.navbar-transparent) .btn-neutral:active {
  color: #9A9A9A; }

/*      Navbar with icons            */
.navbar-icons.navbar .navbar-brand {
  margin-top: 12px;
  margin-bottom: 12px; }

.navbar-icons .navbar-nav .nav-item .nav-link {
  text-align: center;
  padding: 6px 15px;
  margin: 6px 3px; }

.navbar-icons .navbar-nav [class^="pe"] {
  font-size: 30px;
  position: relative; }

.navbar-icons .navbar-nav p {
  margin: 3px 0 0; }

.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .navbar-form .form-control {
    border-radius: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    height: 22px;
    font-size: 14px;
    line-height: 1.5em;
    color: #E3E3E3; }
  .navbar-transparent .navbar-form .form-control,
  [class*="bg"] .navbar-form .form-control {
    color: #FFFFFF;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6); }

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0; }
  .navbar-toggle .icon-bar {
    background-color: #FFFFFF; }
  .navbar-toggle .navbar-collapse,
  .navbar-toggle .navbar-form {
    border-color: transparent; }
  .navbar-toggle.navbar-default .navbar-toggle:hover,
  .navbar-toggle.navbar-default .navbar-toggle:focus {
    background-color: transparent; }

.navbar-light .navbar-nav .nav-link:hover {
  color: #E3E3E3; }

.red {
  color: #ff0000; }

.collapse .navbar-text {
  line-height: 55px; }

.navbar-default .navbar-brand {
  color: #66615B; }

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e; }

.navbar-collapse.show .navbar-nav .nav-item {
  padding-right: 100px; }

.nav-tabs-navigation:last-child {
  border-bottom: 0 none; }
  .nav-tabs-navigation:last-child .nav-stacked {
    border-right: 1px solid #F1EAE0;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0; }
    .nav-tabs-navigation:last-child .nav-stacked .nav-item .nav-link {
      padding: 7px 25px; }

.navbar-nav > .dropdown-menu,
.dropdown .dropdown-menu {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s; }

.navbar-toggler {
  outline: none !important;
  cursor: pointer; }
  .navbar .navbar-toggler .navbar-toggler-bar {
    background: #66615b; }
  .navbar[class*="bg-"] .navbar-toggler .navbar-toggler-bar,
  .navbar.navbar-transparent .navbar-toggler .navbar-toggler-bar {
    background: #fff; }
  .navbar-toggler .navbar-toggler-bar {
    display: block;
    position: relative;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; }

.navbar-toggler .navbar-toggler-bar + .navbar-toggler-bar,
.navbar-toggler .navbar-toggler-icon + .navbar-toggler-icon {
  margin-top: 4px; }

.navbar-toggler-icon {
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  margin: 0 auto;
  background: gray; }

.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none; }

#description-areas .nav-stacked .nav-link.active:before,
#navtabs-row .nav-stacked .nav-link.active:before {
  border-right: 11px solid #F1EAE0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 7px; }

#description-areas .nav-stacked .nav-link.active:after,
#navtabs-row .nav-stacked .nav-link.active:after {
  border-right: 11px solid #FFFFFF;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: -1px;
  bottom: 7px; }

#second-tabs {
  margin-left: 20px; }

.scroll-area {
  max-height: 310px;
  overflow-y: scroll;
  list-style: outside none none;
  padding: 0px; }

.burger-menu .collapse .navbar-nav a {
  color: #333333; }

.navbar-transparent {
  background: transparent !important;
  border-bottom: 1px solid transparent;
  box-shadow: none; }
  .navbar-transparent .dropdown-menu .divider {
    background-color: rgba(255, 255, 255, 0.2); }

.icon-primary {
  color: #51cbce; }

.icon-info {
  color: #51bcda; }

.icon-success {
  color: #6bd098; }

.icon-warning {
  color: #fbc658; }

.icon-danger {
  color: #f5593d; }

.icon-neutral {
  color: #FFFFFF; }

.info {
  padding: 30px 0 30px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto; }
  .info .icon {
    margin-top: 0;
    font-size: 3.4em; }
    .info .icon.icon-sm {
      font-size: 1.6em; }
    .info .icon .fa {
      width: 48px; }
  .info h4,
  .info .h4 {
    margin-top: 15px;
    margin-bottom: 15px; }

.info-horizontal {
  text-align: left;
  margin-top: 0; }
  .info-horizontal .icon {
    float: left;
    margin-top: 5px;
    margin-right: 20px;
    font-size: 2.5em; }
    .info-horizontal .icon .fa {
      width: 48px; }
  .info-horizontal .description {
    overflow: hidden; }
  .info-horizontal .btn {
    margin-left: -19px; }

.page-header {
  background-color: #B2AFAB;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1; }
  .page-header.page-header-small {
    min-height: 65vh !important;
    max-height: 700px; }
  .page-header.page-header-xs {
    min-height: 40vh !important; }
  .page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFFFFF;
    width: 100%; }
  .page-header .motto {
    color: #FFFFFF;
    text-align: center;
    z-index: 2;
    position: relative; }
  .page-header .filter::after {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }
  .page-header .filter.filter-primary::after {
    background-color: rgba(52, 181, 184, 0.5); }
  .page-header .filter.filter-info::after {
    background-color: rgba(43, 169, 205, 0.5); }
  .page-header .filter.filter-success::after {
    background-color: rgba(68, 196, 125, 0.5); }
  .page-header .filter.filter-warning::after {
    background-color: rgba(250, 181, 38, 0.5); }
  .page-header .filter.filter-danger::after {
    background-color: rgba(243, 48, 13, 0.5); }
  .page-header .container {
    color: #FFFFFF;
    position: relative;
    z-index: 3; }
  .page-header .description {
    color: #FFFFFF; }

.header-video.state-play .filter::after {
  opacity: 0; }

.header-video.state-play .video-text {
  opacity: 0; }

.header-video.state-play .btn {
  opacity: 0; }

.header-video.state-play:hover .btn {
  opacity: 1; }

video {
  position: absolute;
  width: 102%; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%); }
  .page-header .presentation-title {
    background: none !important; }
  .subscribe-line .form-group .form-control {
    line-height: 50px !important;
    height: 50px !important; }
  .nav-tabs .nav-item .active.nav-link::before {
    bottom: 2px !important; }
  .nav-tabs .nav-item .active.nav-link::after {
    bottom: 1px !important; }
  .nav-stacked .active.nav-link::before {
    right: 0 !important;
    bottom: 7px !important; }
  .nav-stacked .active.nav-link::after {
    right: -2px !important;
    bottom: 0 !important;
    top: 13px !important; }
  .buy-product .page-carousel .carousel-inner {
    max-height: 570px !important; } }

.footer {
  background-attachment: fixed;
  position: relative;
  line-height: 20px; }
  .footer .footer-nav ul {
    padding-left: 0;
    margin-bottom: 0; }

.footer nav > ul {
  list-style: none;
  font-weight: normal; }

.footer nav > ul > li {
  display: inline-block;
  padding: 10px 15px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center; }

.footer nav > ul a:not(.btn) {
  color: #777777;
  display: block;
  margin-bottom: 3px;
  line-height: 1.6;
  opacity: .8;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600; }

.footer nav > ul a:not(.btn):hover,
.footer nav > ul a:not(.btn):focus {
  color: #777777;
  opacity: 1; }

.footer .copyright {
  color: #777777;
  padding: 10px 0px;
  font-size: 14px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center; }

.footer .heart {
  color: #EB5E28; }

.footer {
  background-color: #FFFFFF;
  line-height: 36px; }
  .footer.footer-black h4 {
    color: #FFFFFF; }
  .footer .links {
    display: inline-block; }
    .footer .links ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-weight: 600; }
      .footer .links ul > li {
        display: inline-block;
        padding-right: 20px; }
      .footer .links ul > li:last-child {
        padding-right: 0px; }
      .footer .links ul a:not(.btn) {
        color: #66615b;
        display: block;
        font-size: 0.9em;
        margin-bottom: 3px; }
        .footer .links ul a:not(.btn):hover, .footer .links ul a:not(.btn):focus {
          color: #403D39; }
      .footer .links ul.uppercase-links {
        text-transform: uppercase; }
      .footer .links ul.stacked-links {
        margin-top: 10px; }
        .footer .links ul.stacked-links > li {
          display: block;
          line-height: 26px; }
        .footer .links ul.stacked-links h4 {
          margin-top: 0px; }
  .footer .social-area {
    padding: 15px 0; }
    .footer .social-area .btn {
      margin-bottom: 3px;
      padding: 8px; }
      .footer .social-area .btn i {
        margin-right: 0 !important;
        color: #fff; }
  .footer hr {
    border-color: #DDDDDD;
    border-width: 1px 0 0;
    margin-top: 5px;
    margin-bottom: 5px; }
  .footer .copyright {
    color: #A49E9E;
    font-size: 0.9em; }
    .footer .copyright ul > li {
      padding-right: 0px; }
  .footer .title {
    color: #403D39; }

.footer-black,
.footer-transparent,
.subscribe-line-transparent {
  background-color: #252422;
  color: #DDDDDD; }
  .footer-black .links ul a:not(.btn),
  .footer-transparent .links ul a:not(.btn),
  .subscribe-line-transparent .links ul a:not(.btn) {
    color: #A49E9E; }
    .footer-black .links ul a:not(.btn):hover, .footer-black .links ul a:not(.btn):focus,
    .footer-transparent .links ul a:not(.btn):hover,
    .subscribe-line-transparent .links ul a:not(.btn):hover,
    .footer-transparent .links ul a:not(.btn):focus,
    .subscribe-line-transparent .links ul a:not(.btn):focus {
      color: #F1EAE0; }
  .footer-black .copyright,
  .footer-transparent .copyright,
  .subscribe-line-transparent .copyright {
    color: #A49E9E; }
    .footer-black .copyright ul > li a:not(.btn),
    .footer-transparent .copyright ul > li a:not(.btn),
    .subscribe-line-transparent .copyright ul > li a:not(.btn) {
      color: #A49E9E; }
  .footer-black hr,
  .footer-transparent hr,
  .subscribe-line-transparent hr {
    border-color: #66615b; }


.footer-transparent,
.subscribe-line-transparent {
  background-size: cover;
  position: relative; }
  .footer-transparent .container, .subscribe-line-transparent .container {
    z-index: 2;
    position: relative; }
  
  .footer-transparent hr,
  .subscribe-line-transparent hr {
    border-color: #A49E9E; }
  
  .footer-transparent .copyright,
  .subscribe-line-transparent .copyright {
    color: #A49E9E; }
    
    .footer-transparent .copyright ul > li a:not(.btn),
    .subscribe-line-transparent .copyright ul > li a:not(.btn) {
      color: #A49E9E; }
  .footer-transparent::after, .subscribe-line-transparent::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(17, 17, 17, 0.5);
    display: block;
    content: "";
    z-index: 1; }

.footer-gray {
  background-color: #F3F2EE; }

.footer-big {
  padding: 30px 0; }
  .footer-big hr {
    margin-top: 20px; }
  .footer-big .copyright {
    margin: 10px 0px 20px; }
  .footer-big .form-group {
    margin-top: 15px; }

.subscribe-line {
  background-color: #FFFCF5;
  padding: 35px 0;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 0; }
  .subscribe-line .form-group {
    margin: 0; }
  .subscribe-line .form-control {
    height: auto;
    font-size: 1.825em;
    border: 0;
    padding: 0;
    font-weight: 300;
    line-height: 54px;
    background-color: transparent; }
  .subscribe-line .btn:not(.btn-lg) {
    margin-top: 7px; }

.subscribe-line-black {
  background-color: #252422; }
  .subscribe-line-black .form-control {
    color: #FFFFFF; }

.subscribe-line-transparent .form-control {
  color: #FFFFFF; }

.social-line {
  padding: 30px 0;
  background-color: #FFFFFF; }
  .social-line .title {
    margin: 0;
    line-height: 40px;
    font-size: 1.6em; }
  .social-line .title-with-lg {
    padding-top: 5px; }
  .social-line .btn {
    width: 100%; }

.social-line-black {
  background-color: #252422;
  color: #FFFFFF; }
  .social-line-black .title {
    color: #FFFFFF; }

.panel {
  border: 0;
  border-bottom: 1px solid #DDDDDD;
  box-shadow: none; }

.panel-default > .panel-heading {
  background-color: #FFFFFF;
  border-color: #FFFFFF; }

.panel-group .panel {
  border-radius: 0; }

.panel-title {
  font-size: 1.35em; }
  .panel-title a {
    display: block;
    padding: .75rem; }
  .panel-title i {
    float: right;
    padding-top: 5px; }

.panel-title a:hover,
.panel-title a:focus {
  text-decoration: none; }

.gsdk-collapse {
  display: block;
  height: 0px;
  visibility: visible;
  overflow: hidden; }

#accordion .panel-title a:hover,
#accordion .panel-title a:focus {
  color: #f5593d; }

#accordion .card-header {
  background-color: #FFFFFF; }

.card-collapse {
  padding: 0 !important; }

.panel-title a[aria-expanded="true"] i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  color: #333333;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease; }
  .card:not(.card-plain):hover {
    box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -moz-transform: translateY(-10px); }
  .card.no-transition:hover, .card.page-carousel:hover, .card.card-refine:hover, .card.card-register:hover {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none; }
  .card a:not(.btn) {
    color: #444444; }
    .card a:not(.btn):hover, .card a:not(.btn):focus {
      color: #333333; }
  .card .card-description {
    color: #9A9A9A;
    margin-bottom: 5px;
    margin-top: 15px; }
  .card img {
    max-width: 100%;
    height: auto;
    border-radius: 12px 12px 0 0; }
  .card[data-radius="none"] {
    border-radius: 0px; }
    .card[data-radius="none"] .header {
      border-radius: 0px 0px 0 0; }
      .card[data-radius="none"] .header img {
        border-radius: 0px 0px 0 0; }
  .card.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0; }
    .card.card-plain .card-body {
      padding-left: 5px;
      padding-right: 5px; }
    .card.card-plain img {
      border-radius: 12px; }
  .card .card-link {
    color: #FFFFFF; }
  .card .card-background-image ul li {
    color: #FFF;
    border-color: rgba(255, 255, 255, 0.3); }
  .card.card-blog,
  .card.card-testimonial .card-description + .card-title {
    margin-top: 30px; }
    .card.card-blog .card-image .img,
    .card.card-testimonial .card-description + .card-title .card-image .img {
      border-radius: 12px; }
    .card.card-blog .card-image .card-title,
    .card.card-testimonial .card-description + .card-title .card-image .card-title {
      text-align: center; }
  .card.card:not(.card-plain) .card-image .img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; }
  .card.card-blog {
    margin-top: 5px; }
    .card.card-blog .btn {
      margin: 0 auto; }
  .card .author {
    display: inline-block; }
    .card .author .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 5px; }
    .card .author .avatar-big {
      width: 100px;
      height: 100px;
      border-radius: 50% !important;
      position: relative;
      margin-bottom: 15px; }
      .card .author .avatar-big.border-white {
        border: 4px solid #FFFFFF; }
      .card .author .avatar-big.border-gray {
        border: 4px solid #ccc; }
  .card .clearfix + .author {
    margin-top: 15px; }
  .card .stats {
    display: inline-block;
    float: right;
    color: #9A9A9A;
    font-size: 12px;
    margin-top: 6px; }
    .card .stats i {
      font-size: 16px;
      position: relative;
      text-align: center;
      top: 1px;
      margin-left: 5px;
      margin-top: -1px;
      opacity: .6; }
  .card .card-category {
    color: #9A9A9A; }
  .card .category-social .fa {
    font-size: 24px;
    position: relative;
    margin-top: -4px;
    top: 2px;
    margin-right: 5px; }
  .card .card-title-uppercase {
    text-transform: uppercase; }
  .card .header {
    position: relative;
    border-radius: 3px 3px 0 0;
    z-index: 3; }
    .card .header.header-with-icon {
      height: 150px; }
    .card .header img {
      width: 100%; }
    .card .header .card-category {
      padding: 15px; }
  .card .card-body .card-footer {
    padding: 0; }
  .card .card-body .card-description + .card-footer {
    padding-top: 10px; }
  .card .more {
    float: right;
    z-index: 4;
    display: block;
    padding-top: 10px;
    padding-right: 10px;
    position: relative; }
  .card .filter,
  .card .header .actions {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.76);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0; }
  .card .header .actions {
    background-color: transparent;
    z-index: 3; }
    .card .header .actions .btn {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  .card:hover .filter {
    opacity: .7; }
  .card:hover .header .social-line,
  .card:hover .header .actions {
    opacity: 1; }
  .card .card-category i,
  .card .label i {
    font-size: 15px; }
  .card > .card-title {
    margin: 0;
    padding: 30px 0 0; }
  .card .card-body {
    padding: 20px; }
    .card .card-body .card-title {
      margin: 5px 0 0 0; }
    .card .card-body .card-category ~ .card-title {
      margin-top: 5px; }
  .card .card-text {
    font-size: 15px;
    color: #66615b;
    padding-bottom: 15px; }
  .card h6 {
    font-size: 12px;
    margin: 0; }
  .card .card-footer {
    padding: 15px;
    background: transparent;
    border-top: 0 none; }
    .card .card-footer .social-line .btn:first-child {
      border-radius: 0 0 0 6px; }
    .card .card-footer .social-line .btn:last-child {
      border-radius: 0 0 6px 0; }
  .card.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: #DDDDDD;
    content: "";
    position: absolute; }
  .card .card-icon {
    display: block;
    margin: 0 auto;
    position: relative;
    text-align: center;
    padding: 30px 0 10px; }
    .card .card-icon i {
      font-size: 40px;
      width: 105px;
      border: 2px solid #ccc;
      border-radius: 50%;
      height: 105px;
      line-height: 105px; }
  .col-lg-4 .card .card-icon i {
    font-size: 80px;
    padding: 22px; }
  .card.card-with-border .card-body {
    padding: 15px 15px 25px 15px; }
  .card.card-with-border .card-footer {
    padding-bottom: 25px; }
  .card.card-with-border:after {
    position: absolute;
    display: block;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    content: "";
    top: 5px;
    left: 5px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 1;
    border-radius: 5px; }
  .card.card-just-text .card-body {
    padding: 50px 65px;
    text-align: center; }
  .card.card-just-text #dLabel {
    float: right; }
    .card.card-just-text #dLabel i {
      font-size: 20px; }
  .card.card-just-text .dropdown-menu {
    margin-top: 55px; }
  .card[data-background="image"] {
    background-position: center center;
    background-size: cover;
    text-align: center; }
    .card[data-background="image"] .card-body {
      position: relative;
      z-index: 2;
      min-height: 280px;
      padding-top: 40px;
      padding-bottom: 40px;
      max-width: 440px;
      margin: 0 auto;
      text-align: center; }
    .card[data-background="image"] .card-title {
      color: #FFF;
      margin-top: 10px; }
    .card[data-background="image"]:after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.56);
      border-radius: 12px; }
    .card[data-background="image"] .card-category {
      color: #FFFFFF; }
  .card[data-background="image"] .image, .card[data-background="color"] .image, .card[data-color] .image {
    border-radius: 6px; }
  .card[data-background="image"] .filter, .card[data-background="color"] .filter, .card[data-color] .filter {
    border-radius: 6px; }
  .card[data-background="image"] .card-description, .card[data-background="color"] .card-description, .card[data-color] .card-description {
    color: rgba(255, 255, 255, 0.8); }
  .card[data-background="image"] .card-title,
  .card[data-background="image"] .card-category,
  .card[data-background="image"] .card-text,
  .card[data-background="image"] .card-body,
  .card[data-background="image"] .card-footer,
  .card[data-background="image"] .stats,
  .card[data-background="image"] small,
  .card[data-background="image"] [class*="text-"],
  .card[data-background="image"] ul li b,
  .card[data-background="image"] a:not(.btn), .card[data-background="color"] .card-title,
  .card[data-background="color"] .card-category,
  .card[data-background="color"] .card-text,
  .card[data-background="color"] .card-body,
  .card[data-background="color"] .card-footer,
  .card[data-background="color"] .stats,
  .card[data-background="color"] small,
  .card[data-background="color"] [class*="text-"],
  .card[data-background="color"] ul li b,
  .card[data-background="color"] a:not(.btn), .card[data-color] .card-title,
  .card[data-color] .card-category,
  .card[data-color] .card-text,
  .card[data-color] .card-body,
  .card[data-color] .card-footer,
  .card[data-color] .stats,
  .card[data-color] small,
  .card[data-color] [class*="text-"],
  .card[data-color] ul li b,
  .card[data-color] a:not(.btn) {
    color: #FFFFFF !important; }
  .card[data-background="image"] a:not(.btn):hover,
  .card[data-background="image"] a:not(.btn):focus, .card[data-background="color"] a:not(.btn):hover,
  .card[data-background="color"] a:not(.btn):focus, .card[data-color] a:not(.btn):hover,
  .card[data-color] a:not(.btn):focus {
    color: #FFFFFF; }
  .card[data-background="image"] .card-icon i, .card[data-background="color"] .card-icon i, .card[data-color] .card-icon i {
    color: #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 0.3); }
  .card[data-background="image"].card-with-border:after, .card[data-background="color"].card-with-border:after, .card[data-color].card-with-border:after {
    border: 1px solid rgba(255, 255, 255, 0.45); }
  .card[data-background="image"] .filter {
    opacity: 0.55;
    filter: alpha(opacity=55); }
  .card[data-background="image"]:hover .filter {
    opacity: 0.75;
    filter: alpha(opacity=75); }
  .card[data-color="blue"] {
    background: #6ec7e0; }
  .card[data-color="green"] {
    background: #86d9ab; }
  .card[data-color="yellow"] {
    background: #fcd27b; }
  .card[data-color="brown"] {
    background: #e4c4a1; }
  .card[data-color="purple"] {
    background: #cc90cc; }
  .card[data-color="orange"] {
    background: #f7765f; }

.btn-center {
  text-align: center; }

.card-big-shadow {
  max-width: 320px;
  position: relative; }
  .card-big-shadow:before {
    background-image: url("http://static.tumblr.com/i21wc39/coTmrkw40/shadow.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: -12%;
    content: "";
    display: block;
    left: -12%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0; }

.card-image.card-big-shadow {
  max-width: 100%; }

.card-image .card {
  background-size: cover;
  min-height: 430px;
  width: 100%; }

.card-image .img-raised {
  margin-top: -20px; }

.page-header .card-pricing.card-plain .card-category,
.page-header .card-pricing.card-plain .card-title {
  color: #FFFFFF; }

.card-plain .card-avatar {
  margin-top: 0; }

.card-refine .panel-title {
  font-size: 1em; }

.card-pricing {
  text-align: center; }
  .card-pricing .card-body {
    padding: 15px !important; }
  .card-pricing .card-category {
    margin: 10px 0 10px;
    color: #333333; }
  .card-pricing .card-icon {
    padding: 35px 0 5px;
    transform: translateY(0%);
    color: #333333; }
    .card-pricing .card-icon.icon-info {
      color: #51bcda; }
    .card-pricing .card-icon.icon-danger {
      color: #f5593d; }
    .card-pricing .card-icon.icon-primary {
      color: #51cbce; }
    .card-pricing .card-icon.icon-success {
      color: #6bd098; }
    .card-pricing .card-icon.icon-warning {
      color: #fbc658; }
  .card-pricing .card-title {
    margin-top: 30px !important; }
  .card-pricing ul {
    list-style: none;
    padding: 0;
    max-width: 240px;
    margin: 20px auto; }
    .card-pricing ul li {
      padding: 5px 0;
      list-style-type: none; }
      .card-pricing ul li b {
        font-weight: 600;
        color: #333333; }
  .card-pricing .btn-neutral {
    color: #66615B; }
    .card-pricing .btn-neutral:hover, .card-pricing .btn-neutral :focus {
      color: #403D39; }
  .card-pricing.card-background-image ul li {
    color: #FFFFFF;
    text-align: center;
    border-color: rgba(255, 255, 255, 0.3); }
    .card-pricing.card-background-image ul li b {
      color: #FFFFFF !important; }
  .card-pricing.card-background-image .card-description {
    color: #FFFFFF !important; }
  .card-pricing.card-background-image .card-title small {
    color: rgba(255, 255, 255, 0.6); }
  .card-pricing.card-plain .card-category,
  .card-pricing.card-plain .card-title {
    color: #333333; }
  .card-pricing.card-plain ul li b {
    font-weight: 600;
    color: #333333; }

.card-profile {
  margin-top: 30px;
  text-align: center; }
  .card-profile .card-cover {
    height: 130px;
    background-position: center center;
    background-size: cover;
    border-radius: 12px 12px 0 0; }
  .card-profile .card-body .card-title {
    margin-top: 5px !important; }
  .card-profile .card-body .card-category {
    margin-bottom: 5px;
    margin-top: 5px; }
  .card-profile .card-avatar {
    max-width: 120px;
    max-height: 120px;
    margin: -60px auto 0;
    border-radius: 50%;
    overflow: hidden; }
    .card-profile .card-avatar.border-white {
      border: 4px solid #FFFFFF; }
    .card-profile .card-avatar.border-gray {
      border: 4px solid #ccc; }

.card-testimonial {
  margin-top: 30px;
  text-align: center; }
  .card-testimonial .card-icon {
    padding: 0; }
    .card-testimonial .card-icon i {
      font-size: 30px;
      border: 0;
      display: block;
      line-height: 100px;
      margin: 0px auto;
      margin-bottom: 30px; }
  .card-testimonial .card-body {
    padding: 15px 30px;
    margin-top: -70px; }
    .card-testimonial .card-body .card-description {
      font-style: italic; }
    .card-testimonial .card-body .card-category {
      margin-bottom: 20px; }
  .card-testimonial .card-avatar {
    margin-top: 0; }
    .card-testimonial .card-avatar .img {
      border-radius: 50%;
      width: 100px;
      height: 100px; }
  .card-testimonial .card-footer .card-title {
    color: #333333;
    text-align: center; }
  .card-testimonial .card-footer .card-category {
    color: #9A9A9A; }
  .card-testimonial .card-footer .card-avatar {
    margin-top: 20px; }
    .card-testimonial .card-footer .card-avatar .img {
      border-radius: 50%;
      width: 60px;
      height: 60px; }

.section {
  padding: 70px 0;
  position: relative;
  background-color: #FFFFFF; }
  .section .card-profile {
    margin-top: 100px; }

.section-with-space {
  padding: 60px 0; }

.section-gray {
  background-color: #EEEEEE; }

.section-nude {
  background-color: #FFFCF5; }

.section-gold {
  background-color: #caac90;
  color: #FFFFFF; }

.section-brown {
  background-color: #A59E94;
  color: #FFFFFF; }

.section-light-blue {
  background-color: #51cbce;
  color: #FFFFFF; }

.section-dark-blue {
  background-color: #506367;
  color: #FFFFFF; }

.section-dark {
  background-color: #151f20; }

.section-image,
.section-gold,
.section-dark {
  position: relative; }
  .section-image .title,
  .section-image .info-title,
  .section-image .card-plain .card-title,
  .section-gold .title,
  .section-gold .info-title,
  .section-gold .card-plain .card-title,
  .section-dark .title,
  .section-dark .info-title,
  .section-dark .card-plain .card-title {
    color: #FFFFFF !important; }
  .section-image .category,
  .section-image .description,
  .section-image .card-plain .card-description,
  .section-image .card-plain .card-category,
  .section-gold .category,
  .section-gold .description,
  .section-gold .card-plain .card-description,
  .section-gold .card-plain .card-category,
  .section-dark .category,
  .section-dark .description,
  .section-dark .card-plain .card-description,
  .section-dark .card-plain .card-category {
    color: rgba(255, 255, 255, 0.7); }
  .section-image hr,
  .section-gold hr,
  .section-dark hr {
    border-color: rgba(255, 255, 255, 0.19); }

.page-header .title,
.page-header .info-title,
.carousel-caption .title,
.carousel-caption .info-title {
  color: #FFFFFF; }

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"],
[class*="support-"],
[class*="privacy-policy-"] {
  padding: 80px 0; }

.section-image {
  background-position: center center;
  background-size: cover; }
  .section-image:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }
  .section-image .container {
    position: relative;
    z-index: 2; }

.section-dark .card-profile.card-plain .card-title {
  color: #FFFFFF !important; }

.section-dark .title {
  color: #FFFFFF; }

.section-image .card-user.card-plain .card-title,
.section-image .card-user.card-plain .card-description {
  color: #FFFFFF; }

.section {
  padding: 70px 0;
  position: relative;
  background-color: #FFFFFF; }

.section-with-space {
  padding: 60px 0; }

.section-gray {
  background-color: #EEEEEE; }

.section-nude {
  background-color: #FFFCF5; }

.section-gold {
  background-color: #caac90;
  color: #FFFFFF; }

.section-brown {
  background-color: #A59E94;
  color: #FFFFFF; }

.section-light-blue {
  background-color: #51cbce;
  color: #FFFFFF; }

.section-dark-blue {
  background-color: #506367;
  color: #FFFFFF; }

.section-dark {
  background-color: #151f20; }

.section-image,
.section-gold,
.section-dark {
  position: relative; }
  .section-image .title,
  .section-image .info-title,
  .section-image .card-plain .card-title,
  .section-gold .title,
  .section-gold .info-title,
  .section-gold .card-plain .card-title,
  .section-dark .title,
  .section-dark .info-title,
  .section-dark .card-plain .card-title {
    color: #FFFFFF !important; }
  .section-image .category,
  .section-image .description,
  .section-image .card-plain .card-description,
  .section-image .card-plain .card-category,
  .section-gold .category,
  .section-gold .description,
  .section-gold .card-plain .card-description,
  .section-gold .card-plain .card-category,
  .section-dark .category,
  .section-dark .description,
  .section-dark .card-plain .card-description,
  .section-dark .card-plain .card-category {
    color: rgba(255, 255, 255, 0.7); }
  .section-image hr,
  .section-gold hr,
  .section-dark hr {
    border-color: rgba(255, 255, 255, 0.19); }

.page-header .title,
.page-header .info-title,
.carousel-caption .title,
.carousel-caption .info-title {
  color: #FFFFFF; }

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
  padding: 80px 0; }

.section-image {
  background-position: center center;
  background-size: cover; }
  .section-image:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }
  .section-image .container {
    position: relative;
    z-index: 2; }

#cd-vertical-nav {
  position: fixed;
  right: -78px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 4; }
  #cd-vertical-nav ul {
    list-style: none;
    padding: 0; }
  #cd-vertical-nav li {
    text-align: right; }
  #cd-vertical-nav a {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%; }
    #cd-vertical-nav a span {
      float: right;
      display: inline-block;
      -webkit-transform: scale(0.6);
      -moz-transform: scale(0.6);
      -ms-transform: scale(0.6);
      -o-transform: scale(0.6);
      transform: scale(0.6); }
    #cd-vertical-nav a:hover .cd-label {
      opacity: 1;
      left: -90%;
      -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
      -moz-transition: -moz-transform 0.2s, opacity 0.2s;
      transition: transform 0.2s, opacity 0.2s; }
    #cd-vertical-nav a:hover span {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }
    #cd-vertical-nav a.is-selected .cd-dot {
      background-color: #51cbce;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); }
    #cd-vertical-nav a:after {
      content: "";
      clear: both;
      display: table; }
  #cd-vertical-nav .cd-dot {
    position: relative;
    top: 8px;
    right: calc(100% - 15px);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f5593d;
    -webkit-transition: -webkit-transform 0.2s, background-color 0.5s;
    -moz-transition: -moz-transform 0.2s, background-color 0.5s;
    transition: transform 0.2s, background-color 0.5s;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  #cd-vertical-nav .cd-label {
    position: relative;
    margin-right: 10px;
    padding: 7px 14px;
    color: white;
    background: rgba(0, 0, 0, 0.53);
    font-size: 10px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 600;
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%; }

.header-2 .header .card-body {
  padding: 20px 20px 5px 20px; }

.header-wrapper .header {
  height: 100vh;
  margin: 0;
  padding: 0;
  border: 0;
  background-position: center center;
  background-size: cover; }
  .header-wrapper .header .filter::after {
    height: 100%; }
  .header-wrapper .header .upper-container {
    margin-top: 18%;
    color: white; }

.header-3 .carousel .carousel-inner .carousel-item img {
  width: 100%;
  height: 100vh;
  border-radius: 0 !important; }

.header-3 .carousel .carousel-inner .carousel-caption {
  bottom: 30% !important; }

.header-3 .carousel .navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 9; }

.header-3 .carousel .carousel-control-prev {
  margin-left: 35px; }

.header-3 .carousel .carousel-control-next {
  margin-right: 35px; }

.header-3 .header .filter:after {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: ""; }

.iframe-container iframe {
  width: 100%; }

.features-3 .iphone-container {
  max-width: 200px;
  margin: 0 auto; }
  .features-3 .iphone-container img {
    max-width: 100%;
    padding-top: 40px; }

.features-4 .ipad-container {
  max-width: 500px;
  margin: 0 auto 0; }
  .features-4 .ipad-container img {
    max-width: 100%; }

.features-4 .info-horizontal {
  padding: 50px 0 0px; }

.features-4 h5.description {
  margin-bottom: 60px; }

.features-5 {
  background-position: center center;
  background-size: cover; }
  .features-5 .info, .features-5 .title {
    color: #FFFFFF; }
  .features-5 .title:first-of-type {
    padding-top: 50px; }
  .features-5 .info .title {
    padding-top: 20px; }
  .features-5 .info p {
    color: rgba(255, 255, 255, 0.8); }
  .features-5 .container [class*=col-] {
    border: 1px solid rgba(255, 255, 255, 0.35);
    border-top: 0;
    border-left: 0; }
    .features-5 .container [class*=col-]:last-child {
      border-right: 0; }
  .features-5 .bottom-line [class*=col-] {
    border-bottom: 0; }
  .features-5 .filter:after {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    top: 0; }

.card.card-blog [class*=col-] .card-image .img {
  border-radius: 12px; }

.card.card-blog .card-category {
  padding-top: 10px; }

.blog-1 .card-blog {
  margin-bottom: 80px; }

.blog-3 .card-blog {
  margin-bottom: 40px; }

.card.card-profile [class*=col-] .card-body {
  padding-top: 0px; }
  .card.card-profile [class*=col-] .card-body .card-title {
    margin-top: 10px !important; }

.section .page-header .container {
  z-index: 2; }

.section .page-header .card .card-body .author .avatar {
  width: 130px;
  height: 130px;
  border: 0; }

.section .page-header .title,
.section .page-header .description {
  color: #FFFFFF; }

.section .page-header .filter:after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1; }

.section .team-4.card-profile {
  margin-top: 70px; }

.section .team-5.section-image .title {
  margin-top: 50px; }

.projects-1 .card-background-image .card-category {
  color: #FFFFFF; }

.projects-1 .project-pills {
  margin: 30px auto; }

.project-4 .card[data-background] {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px; }
  .project-4 .card[data-background] .card-body {
    min-height: auto;
    -webkit-box-flex: initial;
    -webkit-flex: initial;
    -ms-flex: initial;
    flex: initial; }
  .project-4 .card[data-background] .icon {
    z-index: 2; }
  .project-4 .card[data-background]:after {
    background-color: rgba(0, 0, 0, 0.35); }

.project-4 .info {
  padding-top: 0 !important; }

.project-4 .card.card-plain .card-body {
  margin-top: 60px; }

#pricing .container + .filter .title {
  color: #FFFFFF; }

#pricing .container + .filter .description {
  color: #DDDDDD; }

#pricing .pricing-5 .title {
  margin-top: 80px; }

#testimonials .testimonials-1 .title {
  margin-top: 40px; }

#testimonials .testimonials-2 .card {
  margin-top: 10px; }
  #testimonials .testimonials-2 .card .carousel-inner {
    height: 500px; }
    #testimonials .testimonials-2 .card .carousel-inner .carousel-item {
      height: 100%;
      width: 100%; }
      #testimonials .testimonials-2 .card .carousel-inner .carousel-item .carousel-caption {
        position: absolute;
        top: 0px;
        left: 25%;
        z-index: 10;
        padding-top: 30px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center; }
        #testimonials .testimonials-2 .card .carousel-inner .carousel-item .carousel-caption .card-testimonial .card-footer .card-title,
        #testimonials .testimonials-2 .card .carousel-inner .carousel-item .carousel-caption .card-testimonial .card-footer .card-avatar {
          color: #FFFFFF; }

#testimonials .testimonials-2 .card-testimonial .card-avatar {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 70px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  width: 130px;
  margin: 0 auto; }
  #testimonials .testimonials-2 .card-testimonial .card-avatar .img {
    width: 130px;
    height: 130px; }
  #testimonials .testimonials-2 .card-testimonial .card-avatar a {
    left: 38%; }

#testimonials .testimonials-2 .card-testimonial .card-footer {
  margin-top: 0; }

.testimonials-2 .testimonials-people {
  margin-top: -70px; }

.testimonials-2 .left-first-person, .testimonials-2 .left-second-person, .testimonials-2 .left-third-person, .testimonials-2 .left-fourth-person, .testimonials-2 .left-fifth-person, .testimonials-2 .left-sixth-person,
.testimonials-2 .right-first-person, .testimonials-2 .right-second-person, .testimonials-2 .right-third-person, .testimonials-2 .right-fourth-person, .testimonials-2 .right-fifth-person, .testimonials-2 .right-sixth-person {
  opacity: 1 !important; }

.section-testimonials {
  background-image: none; }

.section-testimonials .our-clients {
  margin-top: 135px; }

.section-testimonials .our-clients img {
  width: 100%;
  max-width: 140px;
  display: inline-block; }

.section-testimonials .card-profile .card-title,
.section-testimonials .card-profile .card-description {
  text-align: left !important; }

.section-testimonials .card {
  margin: 0 auto;
  max-width: 460px;
  padding-bottom: 30px; }

.section-testimonials .card img {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }

.section-testimonials .fa-star {
  font-size: 20px;
  color: #fec42d; }

.section-testimonials .card.card-testimonial .card-body {
  margin-top: 0;
  padding: 15px 50px; }

.section-testimonials .page-carousel {
  margin-top: 60px; }

.section-testimonials .testimonials-people {
  position: relative; }

.section-testimonials .testimonials-people > img {
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translate3D(0px, 50px, 0px);
  -webkit-transform: translate3D(0px, 50px, 0px);
  -webkit-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  -moz-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  -o-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  -ms-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s; }

.section-testimonials .testimonials-people .left-first-person {
  left: 2%;
  top: 2%;
  height: 70px;
  width: 70px; }

.section-testimonials .testimonials-people .left-second-person {
  left: 65%;
  top: 100%;
  height: 70px;
  width: 70px; }

.section-testimonials .testimonials-people .left-third-person {
  left: -17%;
  top: 135%;
  height: 120px;
  width: 120px; }

.section-testimonials .testimonials-people .left-fourth-person {
  left: 50%;
  top: 190%;
  height: 55px;
  width: 55px; }

.section-testimonials .testimonials-people .left-fifth-person {
  left: 95%;
  top: 220%;
  height: 35px;
  width: 35px; }

.section-testimonials .testimonials-people .left-sixth-person {
  left: 40%;
  top: 265%;
  height: 95px;
  width: 95px; }

.section-testimonials .testimonials-people .right-first-person {
  right: -10%;
  top: 0;
  height: 60px;
  width: 60px; }

.section-testimonials .testimonials-people .right-second-person {
  right: 30%;
  top: 60%;
  height: 70px;
  width: 70px; }

.section-testimonials .testimonials-people .right-third-person {
  right: 95%;
  top: 95%;
  height: 50px;
  width: 50px; }

.section-testimonials .testimonials-people .right-fourth-person {
  right: 45%;
  top: 145%;
  height: 50px;
  width: 50px; }

.section-testimonials .testimonials-people .right-fifth-person {
  right: 90%;
  top: 195%;
  height: 100px;
  width: 100px; }

.section-testimonials .testimonials-people .right-sixth-person {
  right: 15%;
  top: 260%;
  height: 70px;
  width: 70px; }

#contact-us .contactus-1.section-image .card-contact {
  margin-bottom: 30px;
  margin-top: 50px; }

#contact-us .contactus-1.section-image .title {
  margin-top: 15%; }

#contact-us .contactus-1 .info-horizontal {
  margin-left: 0; }

#contact-us .contactus-1 .description, #contact-us .contactus-1 .info-title {
  color: #333333 !important; }

#contact-us .contactus-1 + .contactus-2 {
  padding-top: 0; }

#contact-us .contactus-2 {
  position: relative; }
  #contact-us .contactus-2 .card {
    top: 60px; }
  #contact-us .contactus-2 .big-map {
    width: 100%;
    height: 100%;
    position: absolute; }

#contact-us .card-contact .card-body .checkbox {
  margin-top: 5px; }

.section-contactus {
  padding-bottom: 0; }

/*          Changes for small display      */
@media (max-width: 767px) {
  #cd-vertical-nav {
    display: none !important; }
  .coloured-cards .card-big-shadow {
    margin: 0 auto; }
  .register-page .page-header {
    max-height: 2000px; }
  .section-testimonials .our-clients {
    margin-top: 0 !important; }
  .navbar-transparent {
    background-color: rgba(0, 0, 0, 0.45); }
  .register .page-header {
    max-height: fit-content; }
  body {
    position: relative;
    font-size: 12px; }
  h6 {
    font-size: 1em; }
  .navbar .container {
    left: 0;
    width: 100%;
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    position: relative; }
  .navbar .navbar-nav .dropdown-menu {
    height: 400px;
    overflow-y: scroll; }
  .demo-header .motto {
    padding-top: 30% !important; }
  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; }
  .navbar-header .navbar-toggle {
    margin-top: 12px;
    width: 40px;
    height: 40px; }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent; }
  .bar1 {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .bar2 {
    opacity: 1; }
  .bar3 {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar1 {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .toggled .bar2 {
    opacity: 0; }
  .toggled .bar3 {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg); }
    45% {
      top: 6px;
      transform: rotate(145deg); }
    75% {
      transform: rotate(130deg); }
    100% {
      transform: rotate(135deg); } }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg); }
    75% {
      -webkit-transform: rotate(130deg); }
    100% {
      -webkit-transform: rotate(135deg); } }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg); }
    75% {
      -moz-transform: rotate(130deg); }
    100% {
      -moz-transform: rotate(135deg); } }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg); }
    45% {
      transform: rotate(-10deg); }
    75% {
      transform: rotate(5deg); }
    100% {
      top: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg); }
    45% {
      -webkit-transform: rotate(-10deg); }
    75% {
      -webkit-transform: rotate(5deg); }
    100% {
      top: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg); }
    45% {
      -moz-transform: rotate(-10deg); }
    75% {
      -moz-transform: rotate(5deg); }
    100% {
      top: 0px;
      -moz-transform: rotate(0); } }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg); }
    45% {
      bottom: 6px;
      transform: rotate(-145deg); }
    75% {
      transform: rotate(-130deg); }
    100% {
      transform: rotate(-135deg); } }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg); }
    75% {
      -webkit-transform: rotate(-130deg); }
    100% {
      -webkit-transform: rotate(-135deg); } }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg); }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg); }
    75% {
      -moz-transform: rotate(-130deg); }
    100% {
      -moz-transform: rotate(-135deg); } }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg); }
    45% {
      transform: rotate(10deg); }
    75% {
      transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      transform: rotate(0); } }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg); }
    45% {
      -webkit-transform: rotate(10deg); }
    75% {
      -webkit-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0); } }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg); }
    45% {
      -moz-transform: rotate(10deg); }
    75% {
      -moz-transform: rotate(-5deg); }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0); } }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  .navbar-nav {
    margin: 1px -15px; }
    .navbar-nav .open .dropdown-menu > li > a {
      padding: 15px 15px 5px 50px; }
    .navbar-nav .open .dropdown-menu > li:first-child > a {
      padding: 5px 15px 5px 50px; }
    .navbar-nav .open .dropdown-menu > li:last-child > a {
      padding: 15px 15px 25px 50px; }
  [class*="navbar-"] .navbar-nav > li > a, [class*="navbar-"] .navbar-nav > li > a:hover, [class*="navbar-"] .navbar-nav > li > a:focus, [class*="navbar-"] .navbar-nav .active > a, [class*="navbar-"] .navbar-nav .active > a:hover, [class*="navbar-"] .navbar-nav .active > a:focus, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus, [class*="navbar-"] .navbar-nav .navbar-nav .open .dropdown-menu > li > a:active {
    color: white; }
  [class*="navbar-"] .navbar-nav > li > a:not(.btn),
  [class*="navbar-"] .navbar-nav > li > a:hover,
  [class*="navbar-"] .navbar-nav > li > a:focus,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:not(.btn),
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover,
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus {
    opacity: .7;
    background: transparent; }
  [class*="navbar-"] .navbar-nav.navbar-nav .open .dropdown-menu > li > a:active {
    opacity: 1; }
  [class*="navbar-"] .navbar-nav .dropdown > a:hover .caret {
    border-bottom-color: #777;
    border-top-color: #777; }
  [class*="navbar-"] .navbar-nav .dropdown > a:active .caret {
    border-bottom-color: white;
    border-top-color: white; }
  .footer .footer-nav,
  .footer .credits {
    margin: 0 auto; }
  #typography .typography-line .note {
    margin-left: 7px !important; }
  .dropdown-menu {
    display: none; }
  .navbar-fixed-top {
    -webkit-backface-visibility: hidden; }
  .social-line .btn {
    margin: 0 0 10px 0; }
  .subscribe-line .form-control {
    margin: 0 0 10px 0; }
  .social-line.pull-right {
    float: none; }
  .footer nav.pull-left {
    float: none !important; }
  .footer:not(.footer-big) nav > ul li {
    float: none; }
  .social-area.pull-right {
    float: none !important; }
  .form-control + .form-control-feedback {
    margin-top: 5px; }
  .form-control.form-control-danger.no-border {
    margin-top: 15px; }
  .navbar-toggle:hover, .navbar-toggle:focus {
    background-color: transparent !important; }
  .btn.dropdown-toggle {
    margin-bottom: 0; }
  .media-post .author {
    width: 25%;
    float: none !important;
    display: block;
    margin: 0 auto 10px; }
  .media-post .media-body {
    width: 100%; }
  .modal-footer .btn-simple {
    padding: 15px; }
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1px solid #dddddd;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch; }
  .typography-line {
    padding-left: 100px !important; }
  .projects-1 .project-pills .nav.nav-pills {
    display: block !important; }
    .projects-1 .project-pills .nav.nav-pills .nav-item:first-child .nav-link {
      border-radius: 12px 12px 0 0 !important;
      margin-left: -1px; }
    .projects-1 .project-pills .nav.nav-pills .nav-item.active:first-child .nav-link {
      border-radius: 12px 12px 0 0 !important;
      margin-left: -1px; }
    .projects-1 .project-pills .nav.nav-pills .nav-item:last-child .nav-link {
      border-radius: 0 0 12px 12px !important;
      margin-left: -1px; }
  .testimonials-2 .testimonials-people img,
  .section-testimonials .testimonials-people img {
    display: none !important; }
  .presentation-page .components-container .card {
    margin-bottom: 40px !important; }
  .btn-wd {
    min-width: 280px; }
  .presentation-page .title-brand .type {
    font-size: 16px;
    transform: translateY(-28px);
    -webkit-transform: translateY(-28px);
    -moz-transform: translateY(-28px);
    -o-transform: translateY(-28px); }
  .presentation-page .section-cards,
  .presentation-page .section-components {
    overflow: hidden; }
    .presentation-page .section-cards .first-card,
    .presentation-page .section-components .first-card {
      top: 750px !important; }
      .presentation-page .section-cards .first-card .grid__link,
      .presentation-page .section-components .first-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .fourth-card,
    .presentation-page .section-components .fourth-card {
      top: 940px !important; }
      .presentation-page .section-cards .fourth-card .grid__link,
      .presentation-page .section-components .fourth-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .fifth-card,
    .presentation-page .section-components .fifth-card {
      top: 950px !important;
      left: 220px !important; }
      .presentation-page .section-cards .fifth-card .grid__link,
      .presentation-page .section-components .fifth-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .sixth-card,
    .presentation-page .section-components .sixth-card {
      top: 1335px !important;
      left: 220px !important; }
      .presentation-page .section-cards .sixth-card .grid__link,
      .presentation-page .section-components .sixth-card .grid__link {
        max-width: 200px !important; }
    .presentation-page .section-cards .seventh-card,
    .presentation-page .section-components .seventh-card {
      top: 1155px !important; }
      .presentation-page .section-cards .seventh-card .grid__link,
      .presentation-page .section-components .seventh-card .grid__link {
        max-width: 200px !important; }
  .presentation-page .section-content .image-container .add-animation {
    max-height: 250px !important;
    max-width: 180px !important; }
  .presentation-page .section-components .image-container .components-macbook {
    max-width: 580px !important;
    max-height: 400px !important; }
  .presentation-page .section-components .image-container .social-img,
  .presentation-page .section-components .image-container .share-btn-img {
    display: none; }
  .presentation-page .section-components .title {
    margin-top: -100px !important; }
  .presentation-page .section-examples {
    padding-top: 0 !important;
    margin-top: 10px !important; }
  .presentation-page .section-icons .icons-nucleo .nc-icon:not(.ninth-left-icon):not(.seventh-left-icon):not(.third-left-icon) {
    display: none !important; }
  .bootstrap-datetimepicker-widget {
    visibility: visible !important; }
  .presentation-page .fog-low {
    margin-bottom: -35px; }
  .presentation-page .presentation-title {
    font-size: 5em; }
  .presentation-page .presentation-subtitle {
    margin-top: 40px; }
  .presentation-page .title-brand {
    max-width: 450px; }
    .presentation-page .title-brand .type {
      font-size: 16px; }
  .demo-icons ul li {
    width: 20%;
    float: left; }
  .info.info-separator:after {
    display: none; }
  .pricing-5 .choose-plan {
    text-align: center; }
    .pricing-5 .choose-plan .nav.nav-pills {
      display: inline-flex; }
  .pricing-5 .description {
    text-align: center; }
  .page-carousel .carousel-inner [class*='col-md'] {
    margin-left: 0 !important; }
  .page-carousel .right.carousel-control {
    margin-right: 0 !important; }
  .page-carousel .left.carousel-control {
    margin-left: 0 !important; }
  .subscribe-line .form-control {
    border: 1px solid #DDDDDD;
    padding-left: 10px; }
  .subscribe-line .btn {
    margin: 0 auto; } }

@media screen and (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    display: block !important;
    top: 0;
    height: 100%;
    width: 230px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid #CCC5B9;
    padding-right: 0px;
    padding-left: 40px;
    padding-top: 15px;
    -webkit-transform: translateX(230px);
    -moz-transform: translateX(230px);
    -o-transform: translateX(230px);
    -ms-transform: translateX(230px);
    transform: translateX(230px);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
    .navbar-collapse ul {
      position: relative;
      z-index: 3;
      height: 95%; }
    .navbar-collapse .navbar-nav > .nav-item:last-child {
      border-bottom: 0; }
    .navbar-collapse .navbar-nav > .nav-item > .nav-link {
      margin: 0px 0px;
      color: #9A9A9A !important;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5em;
      padding: 15px 0; }
      .navbar-collapse .navbar-nav > .nav-item > .nav-link:hover, .navbar-collapse .navbar-nav > .nav-item > .nav-link:active {
        color: #403D39 !important; }
    .navbar-collapse::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #FFFCF5;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
      display: block;
      content: "";
      z-index: 1; }
    .navbar-collapse.has-image::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(17, 17, 17, 0.8);
      display: block;
      content: "";
      z-index: 1; }
  .nav-open .navbar-collapse {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); }
  .nav-open .wrapper {
    left: 0;
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .nav-open .navbar-translate {
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .wrapper .navbar-collapse {
    display: none; }
  .login-page.full-screen .page-header .container {
    margin-top: 30%; }
  .dropdown.show .dropdown-menu,
  .dropdown .dropdown-menu {
    border: 0;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    height: auto; }
    .dropdown.show .dropdown-menu .dropdown-item:hover,
    .dropdown.show .dropdown-menu .dropdown-item:focus,
    .dropdown .dropdown-menu .dropdown-item:hover,
    .dropdown .dropdown-menu .dropdown-item:focus {
      background-color: transparent;
      border-radius: 0; }
    .dropdown.show .dropdown-menu .dropdown-item a:hover, .dropdown.show .dropdown-menu .dropdown-item a:focus,
    .dropdown .dropdown-menu .dropdown-item a:hover,
    .dropdown .dropdown-menu .dropdown-item a:focus {
      color: #403D39; }
    .dropdown.show .dropdown-menu:before, .dropdown.show .dropdown-menu:after,
    .dropdown .dropdown-menu:before,
    .dropdown .dropdown-menu:after {
      display: none; }
  .dropdown .dropdown-menu {
    display: none; }
  .dropdown.show .dropdown-menu {
    display: block;
    position: static;
    float: none;
    background: transparent !important; }
  .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 230px;
    content: "";
    z-index: 1029;
    overflow-x: hidden; }
  .wrapper {
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    background-color: white; }
  .container-cards [class*="col-md-"] {
    padding-left: 5px;
    padding-right: 5px; }
  .container-cards .social-buttons {
    z-index: 1; }
  .full-screen.login .page-header {
    display: grid; }
  .comments.media-area .media {
    display: block; }
    .comments.media-area .media .media-body {
      margin-left: 2em; }
      .comments.media-area .media .media-body p {
        margin-top: 15px; }
  .section-navbars .navbar-collapse,
  .section-navigation .navbar-collapse,
  #navbar-dropdown .navbar-collapse {
    display: none !important; }
  .header-4 video {
    width: auto;
    height: 100vh; }
  .subscribe-line .btn.btn-block {
    width: auto; } }

@media screen and (min-width: 991px) {
  .burger-menu .navbar-collapse {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 230px;
    right: 0px;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid #CCC5B9;
    padding-right: 0px;
    padding-left: 40px;
    padding-top: 15px;
    -webkit-transform: translateX(230px);
    -moz-transform: translateX(230px);
    -o-transform: translateX(230px);
    -ms-transform: translateX(230px);
    transform: translateX(230px);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
    .burger-menu .navbar-collapse .navbar-nav .nav-item:last-child {
      border-bottom: 0; }
    .burger-menu .navbar-collapse .navbar-nav {
      height: 100%;
      z-index: 2;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
    .burger-menu .navbar-collapse::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #FFFCF5;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
      display: block;
      content: "";
      z-index: 1; }
    .burger-menu .navbar-collapse.has-image::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(17, 17, 17, 0.8);
      display: block;
      content: "";
      z-index: 1; }
  .burger-menu .navbar .container .navbar-toggler {
    display: block;
    margin-top: 20px; }
  .burger-menu .navbar-translate {
    width: 100%;
    position: relative;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
  .burger-menu .wrapper {
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    background-color: white; }
  .burger-menu .body .navbar-collapse.collapse {
    height: 100vh !important; }
  .burger-menu .navbar-collapse:before, .burger-menu .navbar-collapse:after,
  .burger-menu .navbar-nav:before,
  .burger-menu .navbar-nav:after {
    display: table;
    content: " "; }
  .nav-open .burger-menu .navbar-collapse.collapse {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px); }
  .nav-open .burger-menu .wrapper {
    left: 0;
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .nav-open .burger-menu .navbar-translate {
    -webkit-transform: translateX(-230px);
    -moz-transform: translateX(-230px);
    -o-transform: translateX(-230px);
    -ms-transform: translateX(-230px);
    transform: translateX(-230px); }
  .burger-menu .dropdown.show .dropdown-menu,
  .burger-menu .dropdown .dropdown-menu {
    border: 0;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto; }
    .burger-menu .dropdown.show .dropdown-menu .dropdown-item:hover,
    .burger-menu .dropdown.show .dropdown-menu .dropdown-item:focus,
    .burger-menu .dropdown .dropdown-menu .dropdown-item:hover,
    .burger-menu .dropdown .dropdown-menu .dropdown-item:focus {
      background-color: transparent;
      border-radius: 0; }
    .burger-menu .dropdown.show .dropdown-menu .dropdown-item a:hover, .burger-menu .dropdown.show .dropdown-menu .dropdown-item a:focus,
    .burger-menu .dropdown .dropdown-menu .dropdown-item a:hover,
    .burger-menu .dropdown .dropdown-menu .dropdown-item a:focus {
      color: #403D39; }
    .burger-menu .dropdown.show .dropdown-menu:before, .burger-menu .dropdown.show .dropdown-menu:after,
    .burger-menu .dropdown .dropdown-menu:before,
    .burger-menu .dropdown .dropdown-menu:after {
      display: none; }
  .burger-menu .dropdown .dropdown-menu {
    display: none; }
  .burger-menu .dropdown.show .dropdown-menu {
    display: block; }
  .lightbox .modal-dialog {
    width: 960px; } }

@media screen and (min-width: 767px) {
  .section-sections .section-cols {
    position: relative;
    z-index: 1; }
  .register-page .page-header .container {
    margin-top: 150px; } }

@media (max-width: 1023px) and (min-width: 767px) {
  .presentation-page .section-content .image-container .area-img {
    max-width: 190px;
    max-height: 270px; }
  .presentation-page .section-content .image-container .info-img {
    max-width: 190px;
    max-height: 270px;
    left: -20%; }
  .presentation-page .section-content .section-description {
    margin-top: 0 !important; }
  .presentation-page .section-components .components-macbook {
    left: -75px !important; }
  .presentation-page .section-cards .codrops-header {
    margin-top: 10px !important; } }

@media (max-width: 1367px) and (min-width: 1024px) {
  .presentation-page .section-components .basic-container {
    margin-left: 5em; } }

@media (max-width: 500px) {
  .presentation-page .section-header .card {
    min-height: 230px; }
  #cards .card-profile {
    margin-top: 50px; }
  .pricing-5 .title {
    text-align: center; }
  .presentation-page .presentation-title {
    font-size: 4em; }
  .presentation-page .title-brand .type {
    font-size: 12px;
    padding: 4px 7px;
    border-radius: 4px;
    top: 0;
    font-weight: 600;
    margin-top: 20px;
    right: 2px; }
  .presentation-page .section-cards .codrops-header .description {
    max-width: 220px; }
  .register-page .page-header .container .info:first-of-type {
    margin-top: 50px; } }

@media screen and (max-width: 1367px) {
  .presentation-page .section-cards,
  .presentation-page .section-components {
    overflow: hidden; } }

@media screen and (min-width: 1025) {
  .section-sections .section-cols {
    position: relative;
    z-index: 1; }
    .section-sections .section-cols .row:first-of-type {
      margin-top: 50px;
      margin-right: -120px;
      margin-left: -15px;
      -webkit-transform: translateX(-80px);
      -moz-transform: translateX(-80px);
      -o-transform: translateX(-80px);
      -ms-transform: translateX(-80px);
      transform: translateX(-80px); }
    .section-sections .section-cols .row:nth-of-type(2) {
      margin-left: -100px;
      margin-right: -15px;
      transform: translateX(80px); }
    .section-sections .section-cols .row:nth-of-type(3) {
      margin-right: -120px;
      margin-left: 0px;
      transform: translateX(-120px); }
    .section-sections .section-cols .row:nth-of-type(4) {
      margin-right: -100px;
      margin-left: -15px;
      transform: translateX(-50px); } }

@media only screen and (min-width: 1024px) {
  .demo-icons ul li {
    width: 12.5%;
    float: left;
    padding: 32px 0; } }

@media (max-width: 1200px) {
  .presentation-page .presentation-title {
    font-size: 7em; }
  .presentation-page .title-brand {
    max-width: 650px; } }

@media (max-width: 1200px) {
  .presentation .section-images .image img {
    width: 100%; } }

@media screen and (max-width: 374px) {
  .footer.footer-big .copyright .pull-left, .footer.footer-big .copyright .pull-right {
    float: none !important; }
  .full-screen.login .card.card-register {
    margin-bottom: 20px; }
  .full-screen.login .demo-footer {
    margin-left: -15px; }
  .about-us .header-wrapper {
    height: auto !important; }
  .presentation-page .section-content .image-container .area-img {
    max-width: 170px !important; } }

/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  src: url("../fonts/nucleo-icons.eot");
  src: url("../fonts/nucleo-icons.eot") format("embedded-opentype"), url("../fonts/nucleo-icons.woff2") format("woff2"), url("../fonts/nucleo-icons.woff") format("woff"), url("../fonts/nucleo-icons.ttf") format("truetype"), url("../fonts/nucleo-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

/*------------------------
	base class definition
-------------------------*/
.nc-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'nucleo-icons';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*------------------------
  change icon size
-------------------------*/
.nc-icon.lg {
  font-size: 1.33333333em;
  vertical-align: -16%; }

.nc-icon.x2 {
  font-size: 2em; }

.nc-icon.x3 {
  font-size: 3em; }

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.nc-icon.square,
.nc-icon.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee; }

.nc-icon.circle {
  border-radius: 50%; }

/*------------------------
  list icons
-------------------------*/
.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.nc-icon-ul > li {
  position: relative; }

.nc-icon-ul > li > .nc-icon {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center; }

.nc-icon-ul > li > .nc-icon.lg {
  top: 0;
  left: -1.35714286em; }

.nc-icon-ul > li > .nc-icon.circle,
.nc-icon-ul > li > .nc-icon.square {
  top: -0.19047619em;
  left: -1.9047619em; }

/*------------------------
  spinning icons
-------------------------*/
.nc-icon.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear; }

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*------------------------
  rotated/flipped icons
-------------------------*/
.nc-icon.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

.nc-icon.rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.nc-icon.rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg); }

.nc-icon.flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.nc-icon.flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1); }

/*------------------------
	font icons
-------------------------*/
.nc-air-baloon::before {
  content: "\ea01"; }

.nc-album-2::before {
  content: "\ea02"; }

.nc-alert-circle-i::before {
  content: "\ea04"; }

.nc-align-center::before {
  content: "\ea03"; }

.nc-align-left-2::before {
  content: "\ea05"; }

.nc-ambulance::before {
  content: "\ea06"; }

.nc-app::before {
  content: "\ea07"; }

.nc-atom::before {
  content: "\ea08"; }

.nc-badge::before {
  content: "\ea09"; }

.nc-bag-16::before {
  content: "\ea0a"; }

.nc-bank::before {
  content: "\ea0b"; }

.nc-basket::before {
  content: "\ea0c"; }

.nc-bell-55::before {
  content: "\ea0d"; }

.nc-bold::before {
  content: "\ea0e"; }

.nc-book-bookmark::before {
  content: "\ea0f"; }

.nc-bookmark-2::before {
  content: "\ea10"; }

.nc-box-2::before {
  content: "\ea11"; }

.nc-box::before {
  content: "\ea12"; }

.nc-briefcase-24::before {
  content: "\ea13"; }

.nc-bulb-63::before {
  content: "\ea14"; }

.nc-bullet-list-67::before {
  content: "\ea15"; }

.nc-bus-front-12::before {
  content: "\ea16"; }

.nc-button-pause::before {
  content: "\ea17"; }

.nc-button-play::before {
  content: "\ea18"; }

.nc-button-power::before {
  content: "\ea19"; }

.nc-calendar-60::before {
  content: "\ea1a"; }

.nc-camera-compact::before {
  content: "\ea1b"; }

.nc-caps-small::before {
  content: "\ea1c"; }

.nc-cart-simple::before {
  content: "\ea1d"; }

.nc-chart-bar-32::before {
  content: "\ea1e"; }

.nc-chart-pie-36::before {
  content: "\ea1f"; }

.nc-chat-33::before {
  content: "\ea20"; }

.nc-check-2::before {
  content: "\ea21"; }

.nc-circle-10::before {
  content: "\ea22"; }

.nc-cloud-download-93::before {
  content: "\ea23"; }

.nc-cloud-upload-94::before {
  content: "\ea24"; }

.nc-compass-05::before {
  content: "\ea25"; }

.nc-controller-modern::before {
  content: "\ea26"; }

.nc-credit-card::before {
  content: "\ea27"; }

.nc-delivery-fast::before {
  content: "\ea28"; }

.nc-diamond::before {
  content: "\ea29"; }

.nc-email-85::before {
  content: "\ea2a"; }

.nc-favourite-28::before {
  content: "\ea2b"; }

.nc-glasses-2::before {
  content: "\ea2c"; }

.nc-globe-2::before {
  content: "\ea2d"; }

.nc-globe::before {
  content: "\ea2e"; }

.nc-hat-3::before {
  content: "\ea2f"; }

.nc-headphones::before {
  content: "\ea30"; }

.nc-html5::before {
  content: "\ea31"; }

.nc-image::before {
  content: "\ea32"; }

.nc-istanbul::before {
  content: "\ea33"; }

.nc-key-25::before {
  content: "\ea34"; }

.nc-laptop::before {
  content: "\ea35"; }

.nc-layout-11::before {
  content: "\ea36"; }

.nc-lock-circle-open::before {
  content: "\ea37"; }

.nc-map-big::before {
  content: "\ea38"; }

.nc-minimal-down::before {
  content: "\ea39"; }

.nc-minimal-left::before {
  content: "\ea3a"; }

.nc-minimal-right::before {
  content: "\ea3b"; }

.nc-minimal-up::before {
  content: "\ea3c"; }

.nc-mobile::before {
  content: "\ea3d"; }

.nc-money-coins::before {
  content: "\ea3e"; }

.nc-note-03::before {
  content: "\ea3f"; }

.nc-palette::before {
  content: "\ea40"; }

.nc-paper::before {
  content: "\ea41"; }

.nc-pin-3::before {
  content: "\ea42"; }

.nc-planet::before {
  content: "\ea43"; }

.nc-refresh-69::before {
  content: "\ea44"; }

.nc-ruler-pencil::before {
  content: "\ea45"; }

.nc-satisfied::before {
  content: "\ea46"; }

.nc-scissors::before {
  content: "\ea47"; }

.nc-send::before {
  content: "\ea48"; }

.nc-settings-gear-65::before {
  content: "\ea49"; }

.nc-settings::before {
  content: "\ea4a"; }

.nc-share-66::before {
  content: "\ea4b"; }

.nc-shop::before {
  content: "\ea4c"; }

.nc-simple-add::before {
  content: "\ea4d"; }

.nc-simple-delete::before {
  content: "\ea4e"; }

.nc-simple-remove::before {
  content: "\ea4f"; }

.nc-single-02::before {
  content: "\ea50"; }

.nc-single-copy-04::before {
  content: "\ea51"; }

.nc-sound-wave::before {
  content: "\ea52"; }

.nc-spaceship::before {
  content: "\ea53"; }

.nc-sun-fog-29::before {
  content: "\ea54"; }

.nc-support-17::before {
  content: "\ea55"; }

.nc-tablet-2::before {
  content: "\ea56"; }

.nc-tag-content::before {
  content: "\ea57"; }

.nc-tap-01::before {
  content: "\ea58"; }

.nc-tie-bow::before {
  content: "\ea59"; }

.nc-tile-56::before {
  content: "\ea5a"; }

.nc-time-alarm::before {
  content: "\ea5b"; }

.nc-touch-id::before {
  content: "\ea5c"; }

.nc-trophy::before {
  content: "\ea5d"; }

.nc-tv-2::before {
  content: "\ea5e"; }

.nc-umbrella-13::before {
  content: "\ea5f"; }

.nc-user-run::before {
  content: "\ea60"; }

.nc-vector::before {
  content: "\ea61"; }

.nc-watch-time::before {
  content: "\ea62"; }

.nc-world-2::before {
  content: "\ea63"; }

.nc-zoom-split::before {
  content: "\ea64"; }

/* all icon font classes list here */
@media screen and (max-width: 991px) {
  #contact-us-form .dropdown.show .dropdown-menu {
    background-color: #fff !important; } }

/*
   * Extra utilities
   */
.flex-equal > * {
  -ms-flex: 1;
  flex: 1; }

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1; } }

.overflow-hidden {
  overflow: hidden; }

.plain-link {
  text-decoration: none;
  color: inherit !important; }

.hide {
  display: none; }

.navbar .navbar-brand {
  padding: 0;
  background-image: url("../img/edthrive-logo-dark.png");
  background-repeat: no-repeat;
  background-size: 160px 55px;
  width: 160px;
  height: 55px;
  display: block; }
  .navbar .navbar-brand .nav-item > a {
    text-transform: uppercase; }

.navbar.transparent {
  background-color: transparent;
  border: none;
  box-shadow: none; }
  .navbar.transparent .navbar-brand {
    background-image: url("../img/logo.png"); }

.nav-down {
  position: fixed;
  top: 0;
  transition: top 0.5s ease-in-out;
  width: 100%; }

.nav-up {
  top: -80px; }

/*
 * Custom translucent site header
 */
.site-header {
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px); }
  .site-header a {
    color: #999;
    transition: ease-in-out color .15s; }
    .site-header a:hover {
      color: #fff;
      text-decoration: none; }
  .site-header img {
    width: auto;
    height: 55px; }

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg); }
  .product-device::before {
    position: absolute;
    top: 10%;
    right: 10px;
    bottom: 10%;
    left: 10px;
    content: "";
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px; }

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5; }

/*Video*/
.no-padding-side {
  padding-left: 0;
  padding-right: 0; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.section {
  padding: 0; }

.page-header {
  background-size: cover;
  background-repeat: no-repeat; }

#home-header .page-header {
  background-image: url("../img/Landing-Page-Header.jpg"); }

#about-us-header .page-header {
  background-image: url("../img/About-Us-Page-Header.jpg"); }

#faq-header .page-header {
  background-image: url("../img/FAQ-Page-Header.jpg"); }

#contact-us .section-image {
  background-image: url("../img/Contact-Us-Page-Background.jpg"); }

#sign-up .section-image {
  background-image: url("../img/Sign-Up-Page-Background.jpg"); }
  #sign-up .section-image.pricing-3 {
    background-image: url("../img/Contact-Us-Page-Background.jpg"); }

.learn-more-however {
  background-image: url("../img/Testimonials-Background.jpg"); }

.learnmore-this-is-why {
  background-image: url("../img/Contact-Us-Page-Background.jpg"); }

.project-4 .card[data-background]:after {
  background-color: rgba(0, 0, 0, 0); }

.text-18 {
  font-size: 18pt; }

.bg-white {
  background-color: #eee; }

.section-testimonials {
  background-image: url("../img/Testimonials-Background.jpg");
  background-repeat: no-repeat;
  background-size: cover; }
  .section-testimonials .card {
    margin: 0 auto;
    max-width: 460px;
    margin-bottom: 30px; }

.about-us .container {
  max-width: 970px; }

.about-us .header-wrapper {
  height: auto; }

.about-us h3.title-uppercase {
  margin-top: 50px; }

.about-us p {
  margin-top: 20px; }

.about-us .creators {
  margin-top: 100px; }

.about-us .more-info {
  margin-top: 50px;
  margin-bottom: 30px; }

.other-wrap {
  height: 0;
  overflow: hidden;
  margin-top: 10px; }

.features-3 .iphone-container {
  max-width: 380px; }
  .features-3 .iphone-container img {
    padding-top: 0; }

.edthrive-footer {
  background-color: #fbad3c; }
  .edthrive-footer-logo {
    width: auto;
    height: 60px; }
