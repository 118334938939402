$black: rgba(0, 0, 0, .85);
$gray: #999;
$gray2: #333;
$white-transparent: rgba(255, 255, 255, .1);
$fullwhite: #fff;
$white: #e5e5e5;
$white2: #eee;
$blue: #39a8c4;
$text-gray: #666;
$orange: #fbad3c;
$orange-gradient: linear-gradient(to right, #f4ab2b , #cf9124);