/*
 * Custom translucent site header
 */

 .site-header {
    background-color: $black;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    a {
      color: $gray;
      transition: ease-in-out color .15s;
      &:hover {
        color: $fullwhite;
        text-decoration: none;
      }
    }
    img {
      width:auto; 
      height:55px;
    }
  }
.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: $gray2;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  &::before {
    position: absolute;
    top: 10%;
    right: 10px;
    bottom: 10%;
    left: 10px;
    content: "";
    background-color: $white-transparent;
    border-radius: 5px;
  }
}
.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: $white;
}
/*Video*/
.no-padding-side{
  padding-left:0;
  padding-right:0;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe, object, embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.section{
  padding: 0;
}
.page-header{
  background-size: cover;
  background-repeat: no-repeat;
}
#home-header{
  .page-header{
    background-image: url("../img/Landing-Page-Header.jpg");
  }
}
#about-us-header{
  .page-header{
    background-image: url("../img/About-Us-Page-Header.jpg");
  }
}
#faq-header{
  .page-header{
    background-image: url("../img/FAQ-Page-Header.jpg");
  }
}
#contact-us{
  .section-image{
    background-image: url("../img/Contact-Us-Page-Background.jpg");
  }
}
#sign-up{
    .section-image{
      background-image: url("../img/Sign-Up-Page-Background.jpg");
      &.pricing-3 {
        background-image: url("../img/Contact-Us-Page-Background.jpg");
      }
    }
}
//Learn more
.learn-more-however{
    background-image: url('../img/Testimonials-Background.jpg')
}
.learnmore-this-is-why{
    background-image: url('../img/Contact-Us-Page-Background.jpg')
}
.project-4 .card[data-background]:after {
    background-color: rgba(0,0,0,0);
}