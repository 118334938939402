.text-18{
    font-size:18pt;
}
.bg-white{
    background-color:$white2;
}
.section-testimonials {
    background-image: url('../img/Testimonials-Background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .card {
        margin: 0 auto;
        max-width: 460px;
        margin-bottom: 30px;
      }
}
.about-us{
    .container{
        max-width: 970px;
    }
    .header-wrapper{
        height: auto;
    }
    h3.title-uppercase{
       margin-top: 50px;
    }
    p{
        margin-top: 20px;
    }
    .creators{
        margin-top: 100px;
    }
    .more-info{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}
.other-wrap{
    height: 0;
    overflow: hidden;
    margin-top: 10px;
}
.features-3 {
    .iphone-container {
        max-width: 380px;
        img{
            padding-top: 0;
        }
    }
}