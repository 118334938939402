.navbar {
    .navbar-brand{
        padding: 0;
        background-image: url("../img/edthrive-logo-dark.png");
        background-repeat: no-repeat;
        background-size: 160px 55px;
        width: 160px;
        height: 55px;
        display: block;
        .nav-item > a{
            text-transform: uppercase;
        }
    }
    &.transparent{
        background-color: transparent;
        border: none;
        box-shadow: none;
        .navbar-brand{
           background-image: url("../img/logo.png"); 
        }
    }
}
.nav-down {
    position: fixed;
    top: 0;
    transition: top 0.5s ease-in-out;
    width: 100%;
}
.nav-up {
    top: -80px;
}